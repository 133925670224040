// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { JobOrder } from './job-order';
import { JobOrderOveragePaymentStatus } from './job-order-overage-payment-status';
import { JobOrderOveragePaymentStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class JobOrderOveragePayment extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** String(50) */
  customerProfileId!: string;
  /** Guid FK jobOrder */
  jobOrderId!: string;
  /** Decimal */
  paymentAmt!: number;
  /** DateTime */
  paymentDate!: Date;
  /** String(100) */
  paymentName!: string;
  /** String(50) */
  paymentProfileId!: string;
  /** Binary */
  rowVersion!: any;
  /** Enum FK status */
  statusId!: JobOrderOveragePaymentStatusEnum;
  /** String(50) */
  transactionKey?: string;
  /** FK jobOrderId */
  jobOrder!: JobOrder;
  /** FK statusId */
  status!: JobOrderOveragePaymentStatus;
}

