<prox-main [parent]="this" [bodyTemplate]="bodyTemplate"></prox-main>
<ng-template #bodyTemplate>
  <p-confirmDialog></p-confirmDialog>
  <h4 class="card-header">
    <span>{{title}}</span>
  </h4>
  <div *ngIf="!isAccountUser" class="flex mb-2">
    <button (click)="onAdd()" pButton type="button" label="Add Account" icon="pi pi-plus" class="p-button-sm"></button>
  </div>
  <div class="flex-grow-1 mt-2">
    <prox-ag-grid [gridOptions]="gridOptions" [rowData]="accounts"></prox-ag-grid>
  </div>
</ng-template>