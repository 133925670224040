<prox-main  [parent]="this" [bodyTemplate]="bodyTemplate" [showBack]="true"></prox-main>
<ng-template #bodyTemplate>
  <p-confirmDialog></p-confirmDialog>
  <div class="mb-2">
    <button [disabled]="!this.hasRight(ProximityRightEnum.CanCreateProgram)" (click)="onAdd()" pButton type="button" label="Add Program" icon="pi pi-plus"
      class="p-button-sm"></button>
    <!-- <span style="margin-left:10px; padding-top: 12px;  font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>     -->      
  </div>
  <div class="flex-grow-1" *ngIf="isPageReady">
    <div class="pt-2 pb-2 w-full h-full">
      <prox-ag-grid [gridOptions]="progGridOptions" [rowData]="programs"></prox-ag-grid>
    </div>
  </div>
</ng-template>