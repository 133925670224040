<prox-main [parent]="this" [bodyTemplate]="bodyTemplate"></prox-main>
<ng-template #bodyTemplate>

  <div *ngIf="isPageReady && entities" class="h-full">

    <div class="flex flex-column h-full">
      <div class="flex ml-2 mt-2 mb-3" >
        <button  (click)="onAdd()" pButton type="button" label="Add {{ entityTypeDisplayName}}" icon="pi pi-plus"
          class="p-button-sm" [disabled]="isAddDisabled()"></button>
        <span style="margin-left:10px; padding-top: 12px; font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>     
        <div class="flex-grow-1"></div>
        <button *ngIf="hasHelp" pButton (click)="showHelp()" class="p-button-rounded p-button-text" icon="pi pi-question-circle"></button>
      </div>

      <p-splitter class="w-full flex-grow-1" layout="vertical" styleClass="h-full" [panelSizes]="[40, 60]">
        <ng-template pTemplate>
          <div class="py-2 w-full h-full">
            <prox-ag-grid [gridOptions]="gridOptions" [rowData]="entities"></prox-ag-grid>
          </div>
        </ng-template>
        <ng-template pTemplate>
          <div class="py-2 w-full h-full">
            <prox-role-rights-frm *ngIf="selectedRow" [proximityRole]="selectedRow" [areaId]="area" [allRights]="rights" [uow]="dbQueryService.uow"
            [isReadOnly]="!canEdit(selectedRow)"></prox-role-rights-frm>
          </div>
        </ng-template>
      </p-splitter>
    
      <div *ngIf="showSaveBar">
        <prox-save-cancel class="mt-2" [uow]="this" (undo)="onUndo()" (save)="onSave()" (cancel)="onCancel()" [isReadOnly]="isAddDisabled()"></prox-save-cancel>
      </div>  
    </div>

  </div>
</ng-template>