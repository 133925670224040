<div class="card-body" *ngIf="isPageReady">

  <form #form="ngForm">
      <p-divider type="solid" align="left">
        <div>
          <b> {{proximityRole.description}} Rights </b>
        </div>
      </p-divider>
      <div class="flex flex-wrap pb-3">
        <div class="w-4 pb-3" *ngFor="let vm of roleRights">
          <p-checkbox [disabled]="isReadOnly" [(ngModel)]="vm.hasRight" [binary]="true" [name]="vm.right.name"
          [label]="formatName(vm.right.name)" [title]="vm.right.description || vm.right.name" (onChange)="toggleRight(vm)"></p-checkbox>
        </div>
      </div>
  </form>
</div>