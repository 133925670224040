<prox-main [parent]="this" [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate"></prox-main>
<ng-template #bodyTemplate>
  <prox-account-activation-checklist *ngIf="this.isValidateActivated" [activationErrors]="activationService.activateErrors" [accountName]="account.name"></prox-account-activation-checklist>
  <div class="mb-2">
    <button (click)="onAagAdd()" pButton type="button" label="Add Administrator Group" icon="pi pi-plus"
      class="p-button-sm" [disabled]="isReadOnly()"></button>
      <span style="margin-left:10px; padding-top: 12px;  font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>
  </div>
  <p-splitter class="w-full flex-grow-1" layout="vertical" styleClass="h-full" [panelSizes]="[40, 60]">
    <ng-template pTemplate>
      <div class="py-2 w-full h-full">
        <prox-ag-grid [gridOptions]="aagGridOptions"></prox-ag-grid>
      </div>
    </ng-template>
    <ng-template pTemplate>
      <div class="flex flex-column w-full h-full">
        <div class="flex ml-2 my-2">
          <p-button [disabled]="!selectedAag || isReadOnly()" (click)="onAdminAdd()" styleClass="p-button p-button-sm"
            label="Add Adminisrators to Administrator Group" icon="pi pi-plus" class="p-button-sm"></p-button>
        </div>
        <div class="flex-grow-1 py-2 w-full h-full">
          <prox-ag-grid [gridOptions]="adminGridOptions" [rowData]="admins">
          </prox-ag-grid>
        </div>
      </div>
    </ng-template>
  </p-splitter>
</ng-template>
<ng-template #footerTemplate>
  <prox-save-cancel [uow]="this" (undo)="onUndo()" (save)="onSave()" (cancel)="onCancel()"></prox-save-cancel>
</ng-template>