/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseService, AgFns, ISortModel, ProxAgFns, Roles, StatusService, SaveAndQueryComponent, StatusChangeDialog } from '@core';
import { Account, AccountUser, ActiveStatus, ActiveStatusEnum, ProximityRightEnum } from '@models';

import { UtilFns } from '@utils';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { DialogService } from 'primeng/dynamicdialog';
import { AccountActivationService, ICheckListItem } from '../services/account-activation.service';

@Component({
  selector: 'prox-account-user-list',
  templateUrl: './account-user-list.component.html',
})
export class AccountUserListComponent extends SaveAndQueryComponent {
  accountId?: string;
  accountUsers: AccountUser[] = [];
  activeStatuses: ActiveStatus[] = [];
  auGridOptions: GridOptions = {};
  account!: Account;
  editLabel = 'Edit';
  editLabelWidth = 50;

  validateActivated = false;
  activationErrors: ICheckListItem[] = [];

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    public activationService: AccountActivationService,
    override dbQueryService: AccountDbQueryService,
    override dbSaveService: AccountDbSaveService,
    private statusService: StatusService,
    public pngDialogService: DialogService
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, 'accountId');
    this.account = await this.dbQueryService.getAccountById(this.accountId);
    UtilFns.assertNonNull(this.account, 'Account');
    this.activeStatuses = await this.dbQueryService.getAll(ActiveStatus);

    this.editLabel = (this.account.getStatusId() > 2) ? 'Read Only' : 'Edit';
    this.editLabelWidth = (this.account.getStatusId() > 2) ? 100 : 50;

    this.auGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onAuGridReady,
      onRowSelected: this.onAuRowSelected,
      onModelUpdated: () => this.clearCurrentSelection(false),
      /*         getRowId: (rowIdparams: GetRowIdParams) => {
          const au = rowIdparams.data as AccountUser;
          return au.id;
        }, */
      onPaginationChanged: (params) => {
        if (params.newPage) {
          this.clearCurrentSelection(true);
        }
      },
      rowSelection: 'single',
      rowModelType: 'serverSide',
    });

    AgFns.captureGridRouteParams(this.auGridOptions, this.route, 'id');
    this.setTitle('Account Users for ' + this.account.name);
    this.isPageReady = true;
  }

  async updateDatasource() {
    if (this.auGridOptions.api == null) {
      return;
    }
    const ds = AgFns.buildDatasource(
      () => this.dbQueryService.createAccountUsersQuery(<string>this.accountId),
      // () => this.dbQueryService.createAccountUsersForAccountAdminIdQuery(this.accountAdminId, false),
      'id'
    );
    this.auGridOptions.api.setServerSideDatasource(ds);
    AgFns.applyGridRouteParams(this.auGridOptions);
  }

  isReadOnly() {
    return (
      !this.hasRight(ProximityRightEnum.CanManageAccountOrganization) || this.account.getStatusId() > 2  
    );
  }

  statusMessage() {
    if (!this.hasRight(ProximityRightEnum.CanManageAccountOrganization)) {
      return 'Not authorized';
    }
    
    let msg = '';
    if (this.account?.activeStatusId == ActiveStatusEnum.Active) {
      return 'Account is Active.  Account Users are Editable.';
    } else if (this.account?.activeStatusId == ActiveStatusEnum.Hold) {
      msg = 'Account is On-Hold and is Editable';
    } else {
      return 'Account is ' + this.account?.activeStatus.name + ' and is Not Editable';
    }
    return msg;
  }
  
  onAuGridReady() {
    const colDefs = [
      {
        ...AgFns.createButtonProps('', this.onEdit.bind(this), {
          label: this.editLabel,
        }),
        width: this.editLabelWidth,
        maxWidth: this.editLabelWidth,
      },

      {
        headerName: 'Last Name',
        field: 'proximityUser.lastName',
        maxWidth: 150,
        filter: 'agTextColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: 'First Name',
        field: 'proximityUser.firstName',
        maxWidth: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Middle Name',
        field: 'proximityUser.middleName',
        maxWidth: 100,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Salutation',
        field: 'proximityUser.salutation',
        maxWidth: 100,
      },
      {
        headerName: 'User Name',
        field: 'proximityUser.username',
        maxWidth: 150,
        filter: 'agTextColumnFilter',
      },
      {
        ...AgFns.createButtonProps('', this.onResetLogin.bind(this), {
          label: 'Reset Login',
        }),
        width: 100,
        maxWidth: 100,
      },
      {
        headerName: 'Program User Group',
        valueGetter: (params) => {
          const au = params.data as AccountUser;
          if (au == null) return;
          return au.programUserGroupMaps.map((x) => x.programUserGroup.name).join(', ');
        },
        sortable: false,
      },
      {
        headerName: 'Gender',
        field: 'gender.name',
        maxWidth: 130,
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'E-Mail',
        field: 'proximityUser.email',
        filter: 'agTextColumnFilter',
      },
      ProxAgFns.getWorkingStatusDef('Working Status', this.statusService),
      {
        headerName: 'User Status',
        field: 'proximityUser.activeStatus.name',
        width: 100,
        maxWidth: 120,
        sortable: false,
      },
      ProxAgFns.getEntityStatusColDef(this.onChangeStatus.bind(this)),
    ];

    const sortModel: ISortModel = [
      { colId: 'proximityUser.lastName', sort: 'asc' },
      { colId: 'proximityUser.firstName', sort: 'asc' },
    ];

    AgFns.initGrid(this.auGridOptions, colDefs, sortModel);
    this.updateDatasource();
  }

  async onChangeStatus(row: AccountUser) {
    const errs: string[] = [];

    const selectedStatusId = await StatusChangeDialog.open(
      this.pngDialogService,
      {
        statuses: this.activeStatuses,
        isAvailable: () => true,
        currentStatus: row.proximityUser.activeStatus,
      },
      {
        header: 'Account User Status',
      }
    );

    if (selectedStatusId == row.proximityUser.activeStatusId) {
      return;
    }

    if (selectedStatusId != null) {
      if (
        !this.selectedRow?.entityAspect.validateEntity() ||
        !(this.selectedRow as AccountUser).proximityUser.entityAspect.validateEntity()
      ) {
        errs.push('Account User failed validation test.  Fix Account User before changing status.');
      }
    }

    if (errs.length > 0) {
      this.dialogService.statusErrorDialog(errs);
      return;
    }

    if (selectedStatusId != null) {
      row.proximityUser.activeStatusId = selectedStatusId;
      this.dialogService.verifyProceedDialog(
        this,
        this.statusService.getStatusExplanation(selectedStatusId, 'Account User'),
        'Account User Status Change'
      );
    }
  }

  async proceedDialog() {
    await this.dbSaveService.saveChanges();
    this.toastr.success('Status change updated', 'Database Activity');
    this.auGridOptions.api?.refreshCells();
  }

  cancelDialog() {
    this.dbSaveService.rejectChanges();
    this.auGridOptions.api?.refreshCells();
  }

  async onAuRowSelected(event: RowSelectedEvent) {
    const au = event.data as AccountUser;
    if (au == null) {
      return;
    }
    if (event.node.isSelected()) {
      this.selectedRow = au;
    } else {
      if (au == this.selectedRow) {
        this.selectedRow = undefined;
      }
    }
    this.updateLocation();
  }

  async onResetLogin(row: AccountUser) {
    await this.dbSaveService.postResetProximityUserLogin(row.proximityUser.id, Roles.AccountUser);
    this.toastr.success('Account User Login has been reset.');
  }

  updateLocation() {
    this.updateGridRouteParams(this.auGridOptions, this.selectedRow?.id);
  }

  clearCurrentSelection(stayOnPage: boolean = true) {
    if (this.selectedRow == null) {
      return;
    }

    this.updateLocation();
    this.selectedRow = undefined;
  }

  onEdit(row: AccountUser) {
    this.updateGridRouteParams(this.auGridOptions, row.id);
    this.router.navigate(['account/accounts', this.accountId, 'manage', 'account-users', row.id]);
  }

  onAdd() {
    this.router.navigate(['account/accounts', this.accountId, 'manage', 'account-users', 'add']);
  }

  async onUsersImport() {
    this.router.navigate(['import', this.accountId], {
      relativeTo: this.route,
    });
  }
}
