import { ColDef, GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AgFns,
  BaseService,
  ISortModel,
  SaveAndQueryComponent,
 
} from '@core';
import { EntityFns } from '@data';
import { Account, NotificationEventAccountMap, NotificationEventEnum, NotificationTemplate, NotificationTypeEnum, ProximityRightEnum } from '@models';
import { StringFns, UtilFns } from '@utils';
import { MenuItem } from 'primeng/api';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { NotificationTemplateDialogComponent } from './notification-template-dialog.component';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { FileUploadHandlerEvent } from 'primeng/fileupload';
import { AllTemplateTypes, TemplateType, TemplateTypeEnum } from 'libs/models/entities/notification-template';



@Component({
  selector: 'prox-account-notification-template-list',
  templateUrl: './account-notification-template-list.component.html',
})
export class AccountNotificationTemplateListComponent extends SaveAndQueryComponent {
  accountId!: string;
  account?: Account;
  accountMaps?: NotificationEventAccountMap[];
  allTemplates: NotificationTemplate[] = [];
  templates: NotificationTemplate[] = [];
  menuItems: MenuItem[] = [];
  gridOptions: GridOptions = {};
  isSuperUser: boolean = false;
  templateTypes = AllTemplateTypes;
  templateType!: TemplateType;
  

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    public override dbSaveService: AccountDbSaveService,
    public override dbQueryService: AccountDbQueryService,
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, 'accountId');
    this.isSuperUser = this.baseService.authService.getUser()?.hasRight(ProximityRightEnum.IsSuperUserForAccounts) ?? false;

    this.gridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onGridReady,
      onRowSelected: this.onRowSelected,
      rowSelection: this.isSuperUser ? 'multiple' : 'single',
      getRowId: undefined, // couldn't refresh rows without setting this
    });

    
    this.templateType = this.templateTypes[0];
    this.account = await this.dbQueryService.getAccountById(this.accountId);

    await Promise.all([
      this.loadTemplates(),
      this.loadAccountMaps(),
    ]);

    AgFns.captureGridRouteParams(this.gridOptions, this.route, 'id');
    this.isPageReady = true;
  }

  

  private async loadTemplates() {
    this.allTemplates = await this.dbQueryService.getNotificationTemplates(this.accountId);
    this.onTemplateTypeChange();
  }

  onTemplateTypeChange() {
    this.templates = this.allTemplates.filter(NotificationTemplate.getTemplateTypeFilter(this.templateType.id));
    this.gridOptions.api?.setRowData(this.templates); 
  }

  private async loadAccountMaps() {
    this.accountMaps =  await this.dbQueryService.getNotificationEventAccountMaps(this.accountId);
  }

  /** Get the menu of template variables */
  // private loadMenuItems() {
  //   return this.templatesService.getTemplateMenuItems().then(x => { this.menuItems = x; });
  // }

  onGridReady(event: GridReadyEvent) {
    const colDefs: ColDef[] = [
      {
        ...AgFns.createIconProps('Global', '', () => null, {
          label: '',
          getIcon: this.getIcon.bind(this),
        }),
        width: 70,
        maxWidth: 70,
      },
      {
        ...AgFns.createButtonProps(' ', this.onEdit.bind(this), {
          label: 'Edit',
          canDisplay: (item) => !!item.accountId || this.isSuperUser,
        }),
        width: 60,
        maxWidth: 60,
      },
      {
        headerName: 'Notification Event/Template Name',
        valueGetter: (params) => {
          const nt = params.data as NotificationTemplate
          if (nt == null) return;
          return nt.templateName || nt.notificationEvent.description
        },
        sortable: true,
        width: 200,
        maxWidth: 200,
      },
      {
        headerName: 'Template Type',
        valueGetter: (params) => {
          const nt = params.data as NotificationTemplate
          if (nt == null) return;
          return nt.notificationEventId != null ? 'Event based' : 'Free form'
        },
        sortable: true,
        width: 200,
        maxWidth: 100,
      },
      {
        headerName: 'Notification Type',
        field: 'notificationType.name',
        sortable: true,
        width: 160,
        maxWidth: 100,
      },
      {
        headerName: 'Template',
        field: 'template',
        filter: 'agTextColumnFilter',
        valueFormatter: (p) => StringFns.stripHtmlTags(p.value),
        // width: 200,
        // maxWidth: 200,
      },
      { headerName: 'isGlobal', valueGetter: (p) => p.data.accountId ?? '' , hide: true },
      // ProxAgFns.getEntityDeleteColDef(this.onDelete.bind(this), { canDisplay: this.canDelete.bind(this)}),
      {
        // ...AgFns.createButtonProps('', this.download.bind(this), { icon: 'pi pi-download', }),
        minWidth: 40,
        maxWidth: 40,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        hide: !this.isSuperUser,
      }
    ];

    const sortModel: ISortModel = [
      { colId: 'isGlobal', sort: 'desc' },
      { colId: 'notificationEvent.description', sort: 'asc' },
      { colId: 'notificationType.name', sort: 'asc' },
      
    ];

    AgFns.initGrid(this.gridOptions, colDefs, sortModel);
  }

  getIcon(row: NotificationTemplate) {
    if (!row.accountId) {
      return 'pi pi-check';
    } else {
      return '';
    }
  }

  onRowSelected() {}

  canAdd() {
    return this.templateType.id != TemplateTypeEnum.All &&  !!this.accountMaps?.length;
  }

  async onEdit(notificationTemplate: NotificationTemplate) {
    const dlg = this.baseService.dialogService.pngDialogService;
    const ok = await NotificationTemplateDialogComponent.openDialog(dlg, { notificationTemplate  },   );
    if (ok) {
      await this.dbSaveService.saveSelectedChanges([notificationTemplate]);
      this.gridOptions.api?.refreshCells();
    } else {
      notificationTemplate.entityAspect.rejectChanges();
      this.gridOptions.api?.refreshCells();
    }
  }

  async onAdd() {
    const template = this.dbSaveService.createEntity(NotificationTemplate, {
      accountId: this.isSuperUser ? undefined : this.accountId,
    });
    await this.editNew(template);
  }


  private async editNew(notificationTemplate: NotificationTemplate) {
    const dlg = this.baseService.dialogService.pngDialogService;
    const ok = await NotificationTemplateDialogComponent.openDialog(dlg, { notificationTemplate, templateType: this.templateType   });
      
   if (ok) {
     await this.dbSaveService.saveSelectedChanges([notificationTemplate]);
     await this.loadTemplates();
   } else {
     EntityFns.deleteOrDetach(notificationTemplate.entityAspect);
   }
   this.gridOptions.api?.redrawRows();

  }

  canCopy() {
    const rows = this.gridOptions.api?.getSelectedRows();
    return rows && rows.length == 1;
  }

  async onCopy() {
    const rows = this.gridOptions.api?.getSelectedRows();
    if (rows && rows.length) {
      const old = rows[0] as NotificationTemplate;
      const props = EntityFns.cloneStruct(old, ["id","rowVersion"]) as any;
      props.accountId = this.accountId;
      // TODO - change event or type so we don't create duplicate template
      const template = this.dbSaveService.createEntity(NotificationTemplate, props);
      template.notificationTypeId = <any> undefined;
      template.accountId = this.isSuperUser ? undefined : this.accountId,
      await this.editNew(template);
    }
  }

  
  canDelete(notificationTemplate: NotificationTemplate) {
    return notificationTemplate.accountId != null;
  }

  async onDelete(notificationTemplate: NotificationTemplate) {
    // if (row.isPreset) {
    //   this.toastr.warning(
    //     'Cannot delete a preset communication',
    //     'Unable to Proceed'
    //   );
    // }
    const name = notificationTemplate.templateName ?? NotificationEventEnum[notificationTemplate.notificationEventId!];
    const ok = await this.baseService.dialogService.askIfOk('Delete Template', 'Are you sure you want to delete template "' + name + '"?');
    if (ok) {
      EntityFns.deleteOrDetach(notificationTemplate.entityAspect);
      await this.dbSaveService.saveSelectedChanges([notificationTemplate]);
      await this.loadTemplates();
    }
  }

  /** Download template as json */
  // download(notificationTemplate: NotificationTemplate) {
  //   const json = JSON.stringify(EntityFns.cloneStruct(notificationTemplate, ['id','rowVersion']));
  //   UtilFns.downloadFile(json, 'application/json', 'template.json');
  // }

  /** Download selected templates as json */
  downloadSelected() {
    const selected = this.gridOptions.api?.getSelectedRows();
    if (!selected || !selected.length) { return; }
    const structs = selected.map(x => EntityFns.cloneStruct(x, ['id','rowVersion']));
    const json = JSON.stringify(structs);
    UtilFns.downloadFile(json, 'application/json', 'templates.json');
  }

  /** Parse uploaded json, create and save entities */
  async uploadHander(event: FileUploadHandlerEvent) {
    const file = event.files.shift();
    if (!file) { return; }
    const data = await file.text();
    let config = JSON.parse(data);
    if (!Array.isArray(config)) { config = [config]; }

    for (const c of config) {
      if (c.accountId) { 
        // if it was an account-specific template, assign it to this account.
        c.accountId = this.accountId;
      }
      if (!this.isDuplicate(c)) {
        const ent = this.dbSaveService.createEntity(NotificationTemplate, c);
        this.templates.push(ent);
      }
    }
    await this.dbSaveService.saveChanges();
    this.gridOptions.api?.setRowData(this.templates);
  }

  private isDuplicate(c: Partial<NotificationTemplate>) {
    if (this.templates.some(x => x.accountId == c.accountId && x.notificationEventId == c.notificationEventId && x.notificationTypeId == c.notificationTypeId)) {
      this.toastr.warning(`Omitting template for ${NotificationEventEnum[c.notificationEventId!]} ${NotificationTypeEnum[c.notificationTypeId!]} because one already exists`);
      return true;
    }
    return false;
  }

}
