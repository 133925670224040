import { Component, Input, OnInit } from '@angular/core';
import { AccountActivationService, ICheckListItem } from './services/account-activation.service';
import { AccountDbQueryService } from './services/account-db-query.service';
import { ColDef, GetRowIdParams, GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import { AgFns, ISortModel } from '@core';
import { Router } from '@angular/router';

@Component({
  selector: 'prox-account-activation-checklist',
  templateUrl: './account-activation-checklist.component.html',
})
export class AccountActivationChecklistComponent implements OnInit {
  @Input() activationErrors: ICheckListItem[] = [];
  @Input() accountName!: string;

  gridOptions: GridOptions = {};

  constructor(
    private queryService: AccountDbQueryService, 
    public activationService: AccountActivationService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.gridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onGridReady,
      getRowId: (rowIdParams: GetRowIdParams) => {
        const x = rowIdParams.data as ICheckListItem;
        return x.index.toString();
      },
    });
  }

  onGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions as GridOptions;
    const [colDefs, sortModel] = this.getColDefsAndSortModel();
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    AgFns.applyGridRouteParams(gridOptions);
  }

  getColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      {
        ...AgFns.createButtonProps(' ', this.onFix.bind(this), {
          label: 'Fix This',
        }),
        minWidth: 80,
        maxWidth: 80,
      },
      { headerName: 'Error', field: 'description', maxWidth: 500 },
      { headerName: 'More Info', field: 'info' },
    ];
    const sortModel: ISortModel = [{ colId: 'index', sort: 'asc' }];
    return [colDefs, sortModel] as const;
  }

  onFix(row: ICheckListItem) {
    this.activationService.routeTo(row.route);
  }
}
