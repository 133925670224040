import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Editor, Range } from '@tiptap/core';
import Link from '@tiptap/extension-link';
import Mention from '@tiptap/extension-mention';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';

/** Tiptap TableRow that allows additional attributes.  Otherwise tiptap discards them.   */
export const AttrTableRow = TableRow.extend({
  addAttributes() {
    // We convert these attributes to handlebars tokens on the server
    return { 'data-id': {}, 'data-type': {} }
  },
})

@Component({
  selector: 'prox-html-editor',
  templateUrl: './html-editor.component.html',
})
export class HtmlEditorComponent implements OnInit {
  /** Template tag items for the {{ menu */
  @Input() suggestItems?: MenuItem[];
  /** Editor content */
  @Input() value?: string;
  /** For SMS templates.  Output is still HTML, but formatting menus are removed except for token menu */
  @Input() isTextOnly = false;
  @Input() isReadOnly = false;
  @Output() valueChange = new EventEmitter<string>();
  @ViewChild('templateMenu') templateMenu!: Menu;

  /** Toggle html code view */
  isSource = false;
  /** Toggle toolbar buttons and editable status */
  isDisabled = false;

  editor = new Editor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Table.configure({
        // resizable: true,
        HTMLAttributes: {
          // style: tableStyles,
        },
      }),
      AttrTableRow,
      // LoopTableRow,
      TableHeader.configure({
        HTMLAttributes: {
          // style: tableHeaderStyles,
        },
      }),
      TableCell.configure({
        HTMLAttributes: {
          // style: tableCellStyles,
        },
      }),      
      Mention.configure({
        renderHTML({ options, node }) {
          // return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}}}`;
          return [
            'span',
            options.HTMLAttributes,
            `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}}}`,
          ]          
        },
        suggestion: {
          char: '{{',
          render: () => ({
            onStart: (props: SuggestionProps<MenuItem>) => {
              if (this.suggestItems) {
                // update MenuItems with commands. Different from setSuggestMenu() because
                // these commands replace the typed '{{' with the template variable
                this.suggestItems.forEach(
                  (x) =>
                    (x.command = (x) => {
                      if (x?.item != null) this.insertVariableFromMenu(x.item, props.range);
                    })
                );
                this.templateMenu.show({ currentTarget: props.decorationNode });
              }
            },
          }),
        } as SuggestionOptions<MenuItem>,
      }),
    ],
    editorProps: {
      attributes: {
        class: 'p-2 focus:border-none outline-none h-full',
        spellcheck: 'false',
      },
    },
  });

  headingItems = [
    {
      label: '<h1>Heading 1</h1>',
      id: 'Heading 1',
      command: () => this.setHeading(1),
      escape: false,
      visible: false,
    },
    {
      label: '<h2>Heading 2</h2>',
      id: 'Heading 2',
      command: () => this.setHeading(2),
      escape: false,
    },
    {
      label: '<h3>Heading 3</h3>',
      id: 'Heading 3',
      command: () => this.setHeading(3),
      escape: false,
    },
    {
      label: '<h4>Heading 4</h4>',
      id: 'Heading 4',
      command: () => this.setHeading(4),
      escape: false,
    },
    {
      label: '<h5>Heading 5</h5>',
      id: 'Heading 5',
      command: () => this.setHeading(5),
      escape: false,
    },
    {
      label: '<h6>Heading 6</h6>',
      id: 'Heading 6',
      command: () => this.setHeading(6),
      escape: false,
    },
    {
      label: '<p>Paragraph</p>',
      id: 'Paragraph',
      command: () => this.editor.chain().focus().clearNodes().run(),
      escape: false,
    },
  ];

  alignItems = [
    {
      label: '<i class="fa fa-align-left mr-2"></i>Left',
      id: 'Left',
      command: () => this.setAlign('left'),
      escape: false,
    },
    {
      label: '<i class="fa fa-align-center mr-2"></i>Center',
      id: 'Center',
      command: () => this.setAlign('center'),
      escape: false,
    },
    {
      label: '<i class="fa fa-align-right mr-2"></i>Right',
      id: 'Right',
      command: () => this.setAlign('right'),
      escape: false,
    },
    {
      label: '<i class="fa fa-align-justify mr-2"></i>Justify',
      id: 'Justify',
      command: () => this.setAlign('justify'),
      escape: false,
    },
  ];

  tableMenuItems = [
    {
      label: 'Insert Table',
      id: 'insertTable',
      command: () => this.foc.insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run(),
      escape: false,
    },
    {
      label: 'Add Column Before',
      id: 'addColumnBefore',
      command: () => this.foc.addColumnBefore().run(),
      escape: false,
    },
    {
      label: 'Add Column After',
      id: 'addColumnAfter',
      command: () => this.foc.addColumnAfter().run(),
      escape: false,
    },
    {
      label: 'Delete Column',
      id: 'deleteColumn',
      command: () => this.foc.deleteColumn().run(),
      escape: false,
    },
    {
      label: 'Add Row Before',
      id: 'addRowBefore',
      command: () => this.foc.addRowBefore().run(),
      escape: false,
    },
    {
      label: 'Add Row After',
      id: 'addRowAfter',
      command: () => this.foc.addRowAfter().run(),
      escape: false,
    },
    {
      label: 'Delete Row',
      id: 'deleteRow',
      command: () => this.foc.deleteRow().run(),
      escape: false,
    },
    {
      label: 'Delete Table',
      id: 'deleteTable',
      command: () => this.foc.deleteTable().run(),
      escape: false,
    },
    {
      label: 'Merge Cells',
      id: 'mergeCells',
      command: () => this.foc.mergeCells().run(),
      escape: false,
    },
    {
      label: 'Split Cell',
      id: 'splitCell',
      command: () => this.foc.splitCell().run(),
      escape: false,
    },
  ]

  ngOnInit() {
    // this.setSuggestMenu();
    this.editor.on('transaction', () => {
      this.valueChange.emit(this.value);
    });
    this.editor.on('update', () => {
      this.valueChange.emit(this.value);
    });
    this.setEnable(!this.isReadOnly);
  }

  /** Update MenuItems with commands */
  setSuggestMenu() {
    if (this.suggestItems) {
      this.suggestItems.forEach(
        (x) =>
          (x.command = (x) => {
            if (x?.item != null) this.insertVariableFromMenu(x.item);
          })
      );
    }
  }

  public setEnable(enable: boolean) {
    this.editor.setEditable(enable);
    this.isDisabled = !enable;
  }

  get foc() {
    return this.editor.chain().focus();
  }

  toggleSource() {
    this.isSource = !this.isSource;
  }

  addTable() {
    let chain = this.editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true });
    // chain = this.addTableStyles(chain);
    chain.run();
  }

  private setHeading(level: any) {
    this.editor.chain().focus().toggleHeading({ level: level }).run();
  }

  private setAlign(where: string) {
    this.editor.chain().focus().setTextAlign(where).run();
  }

  /** Insert template variable at current cursor position */
  private insertVariableFromMenu(x: MenuItem, range?: Range) {
    let content: any;
    let id = x.automationId;
    let chain = this.editor.chain().focus();

    if (id.endsWith('+')) {
      // loop
      id = id.substring(0, id.length - 1);
      if (id == 'orderDetails') {
        content = [this.makeTable(id, ['Product Key','Qty'], ['orderDetailProductKey','orderDetailQty'])];
      } else if (id == 'jobOrders') {
        content = [this.makeTable(id, ['Order ID','Submit Date'], ['{orderLink}','orderSubmitDate'])];
      } else {
        // Generalized table for other loops
        content = [this.makeTable(id, ['Col1','Col2'], ['',''])];
        // nontable = [{ type: 'mention', attrs: { id, label: '#'+id }}, { type: 'hardBreak'}, { type: 'mention', attrs: { id, label: '/'+id }}];
      }

    } else if (id.endsWith('?')) {
      // if block
      id = id.substring(0, id.length - 1);
      content = [{ type: 'mention', attrs: { id, label: '#if '+id }}, {type: 'hardBreak'}, { type: 'mention', attrs: { id, label: '/if' }}];
    } else if (id.endsWith('Link')) {
      // triple {} for raw output, since "Link" variables are <a> tags
      content = { type: 'mention', attrs: { id: '{'+id+'}' }};
    } else if (id.endsWith('-')) {
      // indicates submenu item; no change to inserted value
      id = id.substring(0, id.length - 1);
      content = { type: 'mention', attrs: { id }};
    } else {
      content = { type: 'mention', attrs: { id }};
    }

    if (range) {
      chain = chain.insertContentAt(range, content);
    } else {
      chain = chain.insertContent(content);
    }
    chain.run();
  }

  /** Make a table for looping over loopvar, with some predefined columns */
  private makeTable(loopvar: string, colNames: string[], varNames: string[]) {
    if (!colNames || !colNames.length) { colNames = ['Col1']};
    if (!varNames || !varNames.length) { varNames = ['']};
    const headerCols = colNames.map(c => ({
      type: 'tableHeader',
      content: [{
        type: 'paragraph',
        content: [{ type: 'text', text: c }]
      }]
    }));
    const dataCols = varNames.map(v => ({
      type: 'tableCell',
      content: [{
        type: 'paragraph',
        content: [{ type: 'mention', attrs: { id: v } }],
      }]
    }));

    return {
      type: 'table',
      withHeaderRow: true,
      content: [{
        type: 'tableRow',
        content: headerCols
      },
      {
        type: 'tableRow',
        attrs: { 'data-type': 'mention', 'data-id': loopvar },
        content: dataCols
      }]
    };
  }

}


// const tableStyles = 'border-collapse: collapse; margin: 0; overflow: hidden; table-layout: fixed; width: 100%;';
// const tableHeaderStyles = 'border: 1px solid rgba(61, 37, 20, 0.5); box-sizing: border-box; min-width: 1em; padding: 6px 8px; position: relative; vertical-align: top; background-color: rgba(61, 37, 20, 0.25); font-weight: bold; text-align: left;';
// const tableCellStyles = 'border: 1px solid rgba(61, 37, 20, 0.5); box-sizing: border-box; min-width: 1em; padding: 6px 8px; position: relative; vertical-align: top;';
