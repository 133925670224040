// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { NotificationEvent } from './notification-event';
import { NotificationType } from './notification-type';
import { NotificationEventEnum } from './enums';
import { NotificationTypeEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags
export interface TemplateType {
  id : TemplateTypeEnum,
  name: string;
}

export enum TemplateTypeEnum {
  Named = 1,
  Event = 2,
  All = 3,
}

export const AllTemplateTypes: TemplateType[] = [
  { id: TemplateTypeEnum.Event, name: 'Event based'},
  { id: TemplateTypeEnum.Named, name: 'Freeform'},
  { id: TemplateTypeEnum.All, name: 'All'}
]
/// </module-code>

export class NotificationTemplate extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  getTemplateType()  {
    if (this.notificationEventId != null) {
      return AllTemplateTypes.find(x => x.id == TemplateTypeEnum.Event)!;
    } else {
      return AllTemplateTypes.find(x => x.id == TemplateTypeEnum.Named)!;
    }
  }

  static getTemplateTypeFilter(templateTypeId: TemplateTypeEnum) {
    if (templateTypeId == TemplateTypeEnum.Event) {
      return (t: NotificationTemplate) => t.notificationEventId != null;
    } else if (templateTypeId == TemplateTypeEnum.Named) {
      return (t: NotificationTemplate) => t.notificationEventId == null;
    } else {
      return (t: NotificationTemplate) => true;
    }
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId?: string;
  /** Enum FK notificationEvent */
  notificationEventId?: NotificationEventEnum;
  /** Enum FK notificationType */
  notificationTypeId!: NotificationTypeEnum;
  /** Binary */
  rowVersion!: any;
  /** String */
  template!: string;
  /** String(60) */
  templateName?: string;
  /** FK accountId */
  account!: Account;
  /** FK notificationEventId */
  notificationEvent!: NotificationEvent;
  /** FK notificationTypeId */
  notificationType!: NotificationType;
}
