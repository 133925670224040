// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { ActiveStatus } from './active-status';
import { DocSubmitType } from './doc-submit-type';
import { ReturnPolicy } from './return-policy';
import { Supplier } from './supplier';
import { SupplierAdmin } from './supplier-admin';
import { SupplierSubmissionStatus } from './supplier-submission-status';
import { ActiveStatusEnum } from './enums';
import { DocSubmitTypeEnum } from './enums';
import { SupplierSubmissionStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags
import { Validator } from 'breeze-client';
import { EntityPropertyAnnotation, EntityTypeAnnotation } from '../entity-type-annotations';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class SupplierAccount extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static getEntityTypeAnnotation(): EntityTypeAnnotation {
    const propAnnotations = [
      new EntityPropertyAnnotation('invoiceEmail', {
        validators: [Validator.emailAddress()]
      }),
    ];

    return new EntityTypeAnnotation({
      validators: [],
      propertyAnnotations: propAnnotations
    });
  }

  getStatusParents() {
    return [this.account, this.supplier];
  }

  getStatusId() {
    return this.activeStatusId;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId!: string;
  /** String(20) */
  accountTaxExemptionNumber?: string;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** Boolean */
  allowPartialShipments!: boolean;
  /** Boolean */
  allowReissuanceOrders!: boolean;
  /** Guid FK assignedSupplierAdmin */
  assignedSupplierAdminId?: string;
  /** Guid FK defaultReturnPolicy */
  defaultReturnPolicyId?: string;
  /** Int32 */
  dueDateNumDays?: number;
  /** Enum FK invoiceDocSubmitType */
  invoiceDocSubmitTypeId!: DocSubmitTypeEnum;
  /** String */
  invoiceEmail?: string;
  /** Binary */
  rowVersion!: any;
  /** Guid FK supplier */
  supplierId!: string;
  /** Enum FK supplierSubmissionStatus */
  supplierSubmissionStatusId!: SupplierSubmissionStatusEnum;
  /** FK accountId */
  account!: Account;
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** FK assignedSupplierAdminId */
  assignedSupplierAdmin!: SupplierAdmin;
  /** FK defaultReturnPolicyId */
  defaultReturnPolicy!: ReturnPolicy;
  /** FK invoiceDocSubmitTypeId */
  invoiceDocSubmitType!: DocSubmitType;
  /** FK supplierId */
  supplier!: Supplier;
  /** FK supplierSubmissionStatusId */
  supplierSubmissionStatus!: SupplierSubmissionStatus;
}

