/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/member-ordering */

import { CellKeyDownEvent, ColDef, GetRowIdParams, GridApi, GridOptions } from '@ag-grid-community/core';
import { Component } from '@angular/core';

import * as _ from 'lodash';
import { EntityFns } from '@data';
import { Entity, EntityType, SaveResult } from 'breeze-client';
import { SaveAndQueryComponent } from '../combine-mixins';
import { AgFns, ProxAgFns } from '../ag-grid';
import BaseListEditFrmHtml from './base-list-edit-frm.component.html';
import { UtilFns } from '@utils';

export interface IListEditParams {
  hasAltKey?: boolean;
  keyField?: string;
  showSaveBar?: boolean;
}

export interface IButtonTemplate  {
  title: string;
  icon: string;
}

@Component({
  selector: 'prox-base-list-edit-frm',
  template: '',
})
export abstract class BaseListEditFrmComponent<T extends Entity> extends SaveAndQueryComponent {

  public static HTML = BaseListEditFrmHtml;

  params!: Required<IListEditParams>;
  defaultParams: Required<IListEditParams> = {
    hasAltKey: true, // used for duplicate 'name' detection on save 
    keyField: 'id',
    showSaveBar: true
  }

  type!: { new(): T };
  entityType!: EntityType;
  entityTypeDisplayName!: string;
  origEntities: T[] = [];
  entities: T[] = [];
  hasHelp = false;
  showSaveBar = true;

  gridOptions: GridOptions = {};
  gridApi?: GridApi;

  async initialize(type: { new(): T }, typeDisplayName: string, entities: T[], params?: IListEditParams) {
    this.type = type;
    
    this.entityType = this.dbQueryService.metadataStore.getAsEntityType((this.type as any)._$typeName);
    this.entityTypeDisplayName = typeDisplayName;    
    this.params = { ... this.defaultParams, ...params } as Required<IListEditParams>;
    this.showSaveBar = this.params?.showSaveBar;

    this.origEntities = _.sortBy(entities, e => this.getSortValue(e).toLowerCase());
    this.entities = this.origEntities.slice();

    this.gridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onGridReady,
      onRowSelected: this.onRowSelectedDefault,
      onCellKeyDown: this.onCellKeyDown,
      getRowId: (rowIdParams: GetRowIdParams) => {
        const data = rowIdParams.data;
        return this.getKeyValue(data);
      }
    });

    AgFns.captureGridRouteParams(this.gridOptions, this.route, this.params.keyField);
  }

  abstract createEntity(): Promise<T | T[] | undefined>;
  abstract checkIfInUse(entity: T): Promise<boolean>;
  abstract createDefaultEntity(entity: T): Promise<T | undefined>;

  onGridReady() {
    this.gridApi = this.gridOptions?.api as GridApi;
    const colDefs = this.getColDefs();
    
    AgFns.initGrid(this.gridOptions, colDefs);
    AgFns.sizeColumnsToFit(this.gridOptions); 

    AgFns.applyGridRouteParams(this.gridOptions);

  }

  getColDefs() {
    let colDefs: ColDef[] = [
      ProxAgFns.getEntityStateColDef(),
      { headerName: 'Name', field: 'name', editable: true    },
      { headerName: 'Description',  field: 'description',  editable: true },
      ProxAgFns.getEntityDeleteColDef(this.onDelete.bind(this)),
    ];
    const descProp = this.entityType.getProperty('description', false);
    if (descProp == null) {
      colDefs = colDefs.filter(cd => cd.field != 'description');
    }
    return colDefs;
  }

  isAddDisabled() {
    return false;
  }

  statusMessage(){
    return "";
  }

  override async addCrossValidationErrors()  {
    if (this.params.hasAltKey) {
      EntityFns.checkForDupErrors(this.entities, e => this.getAltKeyValue(e).toLowerCase(), 
      (e, dupName) => this.createValidationError(e, 'name', `Duplicate choices are not allowed: ${dupName}` ));
    }
  }

  override async afterSave(sr: SaveResult) {
    this.origEntities = _.sortBy(this.entities, e => this.getSortValue(e).toLowerCase());
    this.entities = this.origEntities.slice();
  }
  
  getKeyValue(e: T) {
    return e[this.params.keyField];
  }

  getSortValue(e: T) {
    return e['name'];
  }

  getAltKeyValue(e: T) {
    UtilFns.assert('name' in e, 'hasAltKey is true and default getAltKeyValue method is not correct');
    return (e['name'] ?? '') as string;
  }
 
  override async afterUndo() {
    this.entities = this.origEntities.slice();
    this.showEntities();
  }

  async onAdd(){
    const newEntity = await this.createEntity();
    
    if (newEntity == null) return;
    const newEntities = _.isArray(newEntity) ? newEntity : [ newEntity];
    if (newEntities.length == 0) return;
    newEntities.forEach(e => e.entityAspect.validateEntity());
    //this.entities.push(...newEntities);
    this.entities.unshift(...newEntities);
    this.showEntities();
    if (this.gridApi != null ) {
      const getKeyValue = this.getKeyValue.bind(this);
      AgFns.selectGridRowByKey(this.gridApi, getKeyValue, getKeyValue(newEntities[0]) );
    }
  }

  async onAddDefault(entity: T) {
    const newEntity = await this.createDefaultEntity(entity);
    if (newEntity == null) return;
    newEntity.entityAspect.validateEntity();
    //this.entities.push(newEntity);
    this.entities.unshift(newEntity);
    this.showEntities();
    if (this.gridApi != null ) {
      const getKeyValue = this.getKeyValue.bind(this);
      AgFns.selectGridRowByKey(this.gridApi, getKeyValue, getKeyValue(newEntity) );
    }
  }

  async onDelete(entity: T) {
    const inUse = await this.checkIfInUse(entity);
    if (inUse) {
      this.toastr.warning(`You cannot delete a ${this.entityTypeDisplayName } that is in use.`, 'Cannot Delete');
      return false;
    } 

    EntityFns.deleteOrDetach(entity.entityAspect);
    this.selectedRow = undefined;
    _.remove(this.entities, entity as object);
    this.showEntities();
    return true;
  }

  showEntities() {
    // this.entities =_.sortBy(this.entities, e => this.params.getSortValue(e));
    this.gridApi?.setRowData(this.entities);
    this.gridApi?.refreshCells();
  }

  onCellKeyDown(keypress: CellKeyDownEvent) {
    const event = keypress.event as KeyboardEvent;
    if (event.ctrlKey && event.code == "Enter") {
      this.onAdd();
    }
  }  
}
