// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ProximityArea } from './proximity-area';
import { ProximityRoleRightMap } from './proximity-role-right-map';
import { ProximityAreaEnum } from './enums';
import { ProximityRightEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProximityRight extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Enum key */
  id!: ProximityRightEnum;
  /** Enum FK area */
  areaId!: ProximityAreaEnum;
  /** String(250) */
  description?: string;
  /** String(60) */
  name!: string;
  /** FK areaId */
  area!: ProximityArea;
  /** Inv FK proximityRightId */
  proximityRoleRightMaps!: ProximityRoleRightMap[];
}

