import { Injectable } from '@angular/core';
import { AccountUser, ProximityUser } from '@models';
import { sha256 } from 'js-sha256';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GravatarService {
  authUser?: ProximityUser;
  f = 'mp';
  emHash = '';

  constructor(
    private authService: AuthService
  ) { }

  async getAuthGravatarLink() {
    const authUser = await this.authService.getLoggedInUser();
    this.authUser = authUser?.actingAsUser ?? authUser;

    if (this.authUser?.email) {
        this.emHash = sha256(this.authUser?.email);
    }
    return "https://www.gravatar.com/avatar/" + this.emHash + "?d=mp";
  }

  async getGravatarLinkFromAccountUser(au: AccountUser) {
    if (au.proximityUser?.email) {
      this.emHash = sha256(au.proximityUser?.email);
  }
  return "https://www.gravatar.com/avatar/" + this.emHash + "?d=mp"
  }
}
