/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, GridOptions, GridReadyEvent, RowSelectedEvent } from '@ag-grid-community/core';
import {
  Component
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgFns, ISortModel, SaveAndQueryComponent } from '@core';
import { SupplierSubmission } from '@models';
import { BaseService } from '@core';

import { DialogService } from 'primeng/dynamicdialog';

import * as moment from 'moment-mini';
import { DateFns } from '@utils';
import { AccountDbQueryService } from './services/account-db-query.service';
import { AccountDbSaveService } from './services/account-db-save.service';

@Component({
  selector: 'prox-suupplier-submission-status',
  templateUrl: './supplier-submission-status.component.html',
})
export class SupplierSubmissionStatusComponent extends SaveAndQueryComponent {
  
  ssGridOptions: GridOptions = {};
  supplierSubmissions: SupplierSubmission[] = [];
  selectedSs?: SupplierSubmission;

  shouldShowErrorsOnly: boolean = false;
  afterDate!: Date;
  
  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    override dbQueryService: AccountDbQueryService, 
    override dbSaveService: AccountDbSaveService,
    public pngDialogService: DialogService
  ) {
    super(baseService, route, dbSaveService, dbQueryService );
  }

  override async updateFromParams(): Promise<void> {
    
    this.afterDate = DateFns.dateAdd(new Date(), -2, 'weeks');

    this.supplierSubmissions = await this.dbQueryService.getSupplierSubmissions(this.afterDate);

    this.ssGridOptions = AgFns.initGridOptions(this, {
      onRowSelected: this.onSsRowSelected,
      onGridReady: this.onSsGridReady
    });
    
    this.isPageReady = true;
  }

  onSsGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions;
    const colDefs: ColDef[] = [
      
      { headerName: 'Supplier Name', field: 'supplier.name', filter: 'agTextColumnFilter', maxWidth: 120   },
      { headerName: 'Table Name', field: 'tableName',  filter: 'agTextColumnFilter', maxWidth: 140  },
      { headerName: 'Entity Id', field: 'entityId',  filter: 'agTextColumnFilter', maxWidth: 250  },
      { ...AgFns.createButtonProps(' ', this.getEntityDescription.bind(this), { label: 'View'} ), width: 50 },
      { headerName: 'Description', field: '_entityDescription', filter: 'agTextColumnFilter',   },
      { headerName: 'Submission Ts', field: 'submissionTs', filter: 'agDateColumnFilter', maxWidth: 130  },
      { headerName: 'Confirmation Key', field: 'confirmationKey', filter: 'agTextColumnFilter', maxWidth: 120  },
      { headerName: 'Error', field: 'errorMessage', filter: 'agTextColumnFilter',   },
      
    ];
    const sortModel: ISortModel = [
      { colId: 'submissionTs', sort: 'desc' },
    ]
    
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    AgFns.selectFirstRow(gridOptions);
  }

  async getEntityDescription(row: SupplierSubmission) {
    const descr = await this.dbQueryService.getEntityDescription(row.tableName, row.entityId);
    row["_entityDescription"] = descr;
    AgFns.refreshGrid(this.ssGridOptions, this.supplierSubmissions);
  }
  
  async refresh() {
    this.supplierSubmissions = await this.dbQueryService.getSupplierSubmissions(this.afterDate);
    if (this.shouldShowErrorsOnly) {
      this.supplierSubmissions = this.supplierSubmissions.filter(x => x.errorMessage != null);
    }
    AgFns.refreshGrid(this.ssGridOptions, this.supplierSubmissions);
  }

  async submitAllPending() {
    await this.wrapBusy(async () => {
      await this.dbSaveService.postPendingSupplierSubmissions();
      this.refresh();
    });
  }

  async onSsRowSelected(event: RowSelectedEvent) {
    const ss = <SupplierSubmission> event.data;
    if (ss == null) {
      return;
    }

    if (event.node.isSelected()) {
      this.selectedSs = ss;
    }

  }
  
  onView(row: SupplierSubmission) {
    // this.router.navigate(['supplier/supplier-add-edit', row.id]);
  }

  
}
