// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ActiveStatus } from './active-status';
import { ProximityRole } from './proximity-role';
import { ActiveStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags
import { Validator } from 'breeze-client';
import { EntityPropertyAnnotation, EntityTypeAnnotation } from '../entity-type-annotations';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProximityUser extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static getEntityTypeAnnotation(): EntityTypeAnnotation {
    const propAnnotations = [
      new EntityPropertyAnnotation('email', {
        validators: [Validator.emailAddress()]
      }),
    ];


    return new EntityTypeAnnotation({
      validators: [],
      propertyAnnotations: propAnnotations
    });

  }

  get name(): string {
    return this.firstName + ' ' + this.lastName;
  }

  get fullName() {
    if (this.salutation) {
      return this.salutation + ' ' + this.firstName + ' ' + this.lastName;      
    };
    return this.firstName + ' ' + this.lastName;
  }

  get formalName() {
    return this.lastName + ', ' + this.firstName;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Boolean */
  acceptSms?: boolean;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** String(50) */
  cellNumber?: string;
  /** String(100) */
  email!: string;
  /** String(10) */
  extension?: string;
  /** String(50) */
  firstName!: string;
  /** String(50) */
  lastName!: string;
  /** String(50) */
  middleName?: string;
  /** Guid FK proximityRole */
  proximityRoleId!: string;
  /** Binary */
  rowVersion!: any;
  /** String(20) */
  salutation?: string;
  /** String(50) */
  telephoneNumber?: string;
  /** String(50) */
  title?: string;
  /** String(50) */
  username!: string;
  /** Boolean */
  wasPopulatedFromUniformax?: boolean;
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** FK proximityRoleId */
  proximityRole!: ProximityRole;
}

