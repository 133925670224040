
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService, Roles } from '@core';
import { ProximityRightEnum } from '@models';

/** Redirect to the appropriate home page base on user role */
export const homeGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router: Router = inject(Router);
    
  const user = await authService.getLoggedInUser();
  if (user) {
    
    // const roles = user?.roles || [];
    if (user.hasRight(ProximityRightEnum.CanManageAccountAdministration, ProximityRightEnum.CanManageAccountOrganization, ProximityRightEnum.CanEditProgram)) {
      return router.createUrlTree(['/account/accounts']);
    }
    else if (user.hasRight(ProximityRightEnum.CanApproveJobOrderByApprovalTree, ProximityRightEnum.CanEditUserByApprovalTree)) {
      return router.createUrlTree(['/account-admin/accounts', user?.accountId]);
    }
    else if (user.hasRight(ProximityRightEnum.CanCreateJobOrders)) {
      return router.createUrlTree(['/account-user/accounts', user?.accountId]);
    }
    else if (user.hasRight(ProximityRightEnum.CanManageSupplierAdministration, ProximityRightEnum.CanManageSupplierPartnerships, ProximityRightEnum.CanManageSupplierProducts)) {
      return router.createUrlTree(['/supplier/suppliers', user?.supplierId, 'manage']);
    }
    // shouldn't get here - every logged-in user should have one of the above roles
    return false;
  }    
  return true;
}
 