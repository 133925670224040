
import { CellValueChangedEvent, GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AgFns, ISortModel, ProxAgFns, UtilDialogService } from '@core';
import { Addon, Feature, Program, ProgramAllowance, ProgramAllowanceAddonException, ProgramAllowanceFeatureException, ProgramAllowanceRemainderRule, ProgramAllowanceUserGroupMap, ProgramIssuance, ProgramIssuanceUserGroupMap, ProgramUserGroup, ProgramViolationRule } from '@models';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgramFrmComponent } from './program-frm.component';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { EntityFns } from '@data';
import * as _ from 'lodash';
import { UtilFns } from '@utils';
import { ToastrService } from 'ngx-toastr';
import { Entity } from 'breeze-client';


@Component({
  selector: 'prox-program-allowances-sub',
  templateUrl: './program-allowances.sub.component.html',
})
export class ProgramAllowancesSubComponent implements OnInit, OnChanges {
  @Input() parent!: ProgramFrmComponent;
  @Input() visible!: boolean;
  // @Input() program!: Program;

  paGridOptions: GridOptions = {};
  programAllowances?: ProgramAllowance[];
  selectedPa?: ProgramAllowance;

  availablePaPugs: ProgramUserGroup[] = [];
  paPugGridOptions: GridOptions = {};
  // Note: these will both use the pugs array from above to provide a list of ProgramUserGroups among which only those in the ProgramAllowanceUserGroup or the ProgramIssuanceUserGroup will be checked

  paFeatureGridOptions: GridOptions = {};
  paAddonGridOptions: GridOptions = {};
  // Note: this is used to provide a list of Features among which only those in the ProgramAllowanceFeatureException will be checked
  paFeatures!: Feature[];
  paAddons!: Addon[];

  
  programAllowanceRemainderRules!: ProgramAllowanceRemainderRule[];
  programViolationRules!: ProgramViolationRule[];

  constructor(
    public dbQueryService: AccountDbQueryService,
    public dbSaveService: AccountDbSaveService,
    public dialogService: UtilDialogService,
    public pngDialogService: DialogService,
    public toastr: ToastrService
  ) {
    //
  }

  get program() {
    return this.parent.program;
  }

/*   isEditable() {
    return this.parent.authCanEdit && !this.isReadOnly();
  } */

  async ngOnInit() {
    this.paGridOptions = AgFns.initGridOptions(
      this,
      {
        onGridReady: this.onPaGridReady,
        onRowSelected: this.onPaRowSelected,
        rowSelection: 'single',
        rowModelType: 'clientSide',
        getRowId: (params) => params.data.id,
      },
    );

    this.paPugGridOptions = AgFns.initGridOptions(
      this,
      {
        onGridReady: this.onPaPugGridReady,
        onCellValueChanged: this.onPaAugCellValueChanged,
        rowSelection: 'single',
        rowModelType: 'clientSide',
        // params.data is ProgramUserGroup
        getRowId: (params) => params.data.id,
      },
    );

    this.paFeatureGridOptions = AgFns.initGridOptions(
      this,
      {
        onGridReady: this.onPaFeatureGridReady,
        rowSelection: 'single',
        rowModelType: 'clientSide',
        // params.data is Feature
        getRowId: (params) => params.data.id,
      },
    );

    this.paAddonGridOptions = AgFns.initGridOptions(
      this,
      {
        onGridReady: this.onPaAddonGridReady,
        rowSelection: 'single',
        rowModelType: 'clientSide',
        // params.data is Feature
        getRowId: (params) => params.data.id,
      },
    );

    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  async refresh(forceRefresh = false) {
    if (!this.visible && !forceRefresh) return;
    this.parent.updateComponentNameMap(this);

    this.programViolationRules = await this.dbQueryService.getAll(
      ProgramViolationRule
    );

    this.programAllowanceRemainderRules = await this.dbQueryService.getAll(
      ProgramAllowanceRemainderRule
    );

    const isLoaded = this.program.entityAspect.isNavigationPropertyLoaded("programAllowances");
    if (isLoaded && !forceRefresh) {
      this.programAllowances = this.program.programAllowances;
    } else {
      const programAllowances = await this.dbQueryService.getProgramAllowances(this.program.id);

      this.programAllowances = _.sortBy(
        programAllowances,
        (pa) => pa.name
      );
      
      this.program.entityAspect.markNavigationPropertyAsLoaded('programAllowances');
    }

    // needed to refresh product type tag dropdowns and editability
    this.onPaGridReady();
    this.onPaPugGridReady();
    this.onPaFeatureGridReady();
    this.onPaAddonGridReady();
    
    AgFns.refreshGrid(this.paGridOptions, this.programAllowances);

    const [r0, r1] = await Promise.all([
      this.dbQueryService.getFeaturesByProgram(this.parent.program.id, true),
      this.dbQueryService.getAddonsByProgram(this.parent.program.id, true),
    ]);

    this.paFeatures = r0;
    this.paAddons = r1;

    AgFns.refreshGrid(this.paFeatureGridOptions, this.paFeatures);
    AgFns.refreshGrid(this.paAddonGridOptions, this.paAddons);
    this.refreshAvailablePaAugs(this.selectedPa);

    AgFns.sizeColumnsToFit(this.paGridOptions);

    AgFns.selectDefaultRow(this.paGridOptions);
  }

  onPaGridReady() {
    const colDefs = [
      ProxAgFns.getEntityStateColDef(),
      {
        headerName: 'Allowance Names',
        field: 'name',
        editable: !this.parent.isReadOnly(),
        sortable: false,
      },
      // { headerName: 'Rank', field: 'displayOrder', hide: true },
      {
        headerName: 'Allowance',
        field: 'allowanceAmt',
        sortable: false,
        editable: !this.parent.isReadOnly(),
      },
      {
        ...AgFns.createCheckboxProps(
          'Allow Over-Purchase?',
          'allowOverAllowancePurchases',
          160
        ),
        editable: !this.parent.isReadOnly(),
        sortable: false,
      },
      {
        ...AgFns.createCheckboxProps(
          'Is Freight Charged To Allowance?',
          'isFreightChargedToAllowance',
          210
        ),
        editable: !this.parent.isReadOnly(),
        sortable: false,
      },
      {
        headerName: 'Estimated Freight Charge Rate',
        field: 'estimatedFreightChargeRate',
        editable: !this.parent.isReadOnly(),
        type: 'numericColumn',
        valueParser: (p) => UtilFns.parsePct(p.newValue),
        valueFormatter: (p) => UtilFns.fmtPct(p.value, 2),
      },
      this.parent.ifEditable(ProxAgFns.getEntityDeleteColDef(this.onPaDelete.bind(this))),
    ];
    const sortModel: ISortModel = [];
    AgFns.initGrid(this.paGridOptions, colDefs, sortModel);
  }

  onPaPugGridReady() {
    const colDefs = [
      {
        headerName: 'Applies to Group',
        field: 'name',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        ...AgFns.createIncludedInListCheckBoxProps(
          ProgramAllowanceUserGroupMap,
          this.dbQueryService.uow,
          'Is Included?',
          (e) => {
            return (
              this.selectedPa?.programAllowanceUserGroupMaps.find(
                (x) => x.programUserGroupId == e.data.id
              ) != null
            );
          },
          (e) => {
            return {
              programAllowanceId: this.selectedPa?.id,
              programUserGroupId: e.data.id,
            };
          },
            !this.parent.isReadOnly(),
        ),
      },
    ];
    const sortModel: ISortModel = [{ colId: 'name', sort: 'asc' }];
    AgFns.initGrid(this.paPugGridOptions, colDefs, sortModel);
  }

  onPaFeatureGridReady() {
    const colDefs = [
      {
        headerName: 'Product Feature',
        field: 'name',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        ...AgFns.createIncludedInListCheckBoxProps(
          ProgramAllowanceFeatureException,
          this.dbQueryService.uow,
          'Exclude Feature Charge From Allowance?',
          (e) => {
            return (
              this.selectedPa?.programAllowanceFeatureExceptions.find(
                (x) => x.featureId == e.data.id
              ) != null
            );
          },
          (e) => {
            return {
              programAllowanceId: this.selectedPa?.id,
              featureId: e.data.id,
            };
          },
            !this.parent.isReadOnly(),
        ),
        width: 250,
        maxWidth: 250,
      },
    ];
    const sortModel: ISortModel = [{ colId: 'name', sort: 'asc' }];
    AgFns.initGrid(this.paFeatureGridOptions, colDefs, sortModel);
  }

  onPaAddonGridReady() {
    const colDefs = [
      {
        headerName: 'Add-On',
        field: 'nameAndLocation',
        cellRenderer: 'agGroupCellRenderer',
        width: 450
      },
      {
        ...AgFns.createIncludedInListCheckBoxProps(
          ProgramAllowanceAddonException,
          this.dbQueryService.uow,
          'Exclude Add-on Charge From Allowance?',
          (e) => {
            return (
              this.selectedPa?.programAllowanceAddonExceptions.find(
                (x) => x.addonId == e.data.id
              ) != null
            );
          },
          (e) => {
            return {
              programAllowanceId: this.selectedPa?.id,
              addonId: e.data.id,
            };
          },
            !this.parent.isReadOnly(),
        ),
        width: 250,
        maxWidth: 250,
      },
    ];
    const sortModel: ISortModel = [{ colId: 'nameAndLocation', sort: 'asc' }];
    AgFns.initGrid(this.paAddonGridOptions, colDefs, sortModel);
  }

  onPaRowSelected(event: RowSelectedEvent) {
    if (!event.node.isSelected()) return;
    const pa = event.data as ProgramAllowance;
    if (pa == null) return;
    this.selectedPa = pa;
    if (pa.entityAspect.entityState.isDeleted()) {
      this.selectedPa = undefined;
      return;
    }
    // augs is list of augs that are already associated with this pa and those that have not yet been associated with any pa.
    this.refreshAvailablePaAugs(pa);

    AgFns.refreshGrid(this.paFeatureGridOptions, this.paFeatures);
    AgFns.refreshGrid(this.paAddonGridOptions, this.paAddons);
  }

  refreshAvailablePaAugs(pa?: ProgramAllowance) {
    if (pa == null) {
      this.availablePaPugs = [];
    } else {
      const usedPaAugIds = new Set(
        _.flatten(
          this.program.programAllowances.map((pa) =>
            pa.programAllowanceUserGroupMaps.map((paug) => paug.programUserGroupId)
          )
        )
      );
      // OLD Wrong code 
      // const availableAugs = this.program.account.programUserGroups.filter((x) => !usedPaAugIds.has(x.id));

      const pugs = this.program.approvalTree.approvalTreeUserGroups.map(x => x.programUserGroup);
      const availableAugs = pugs.filter((x) => !usedPaAugIds.has(x.id));

      const currentPaAugs = pa.programAllowanceUserGroupMaps.map(
        (x) => x.programUserGroup
      );
      availableAugs.push(...currentPaAugs);
      this.availablePaPugs = availableAugs;
    }
    AgFns.refreshGrid(this.paPugGridOptions, this.availablePaPugs, 'sizeToFit');
    return this.availablePaPugs;
  }

  async onPaAdd() {
    if (this.programAllowances == null) return;
    if (this.program.approvalTree == null) {
      await this.dialogService.okDialog("Unable to add", "A Proximity Organization must be specified before you can add an allowance");
      return;
    }

    const pa = this.dbSaveService.createEntity(ProgramAllowance, {
      programId: this.program.id,
      // estimatedFreightChargeRate:
    });
    pa.entityAspect.validateEntity();

    this.programAllowances?.push(pa);
    AgFns.refreshGrid(this.paGridOptions, this.programAllowances);
    await UtilFns.wait(0);
    AgFns.selectGridRowByKey(this.paGridOptions, (e) => e.id, pa.id);
  }

  async onPaDelete(pa: ProgramAllowance) {
    if (!this.programAllowances) return;

    UtilFns.assertNonNull(pa, 'Program Allowance should have been found');
    pa.programAllowanceUserGroupMaps
      .slice()
      .forEach((x) => EntityFns.deleteOrDetach(x.entityAspect));
    // pa.programAllowanceExceptions.slice().forEach( paug => EntityFns.deleteOrDetach(paug.entityAspect));
    EntityFns.deleteOrDetach(pa.entityAspect);

    _.remove(this.programAllowances, pa);
    AgFns.refreshGrid(this.paGridOptions, this.programAllowances);
    AgFns.refreshGrid(this.paPugGridOptions, []);
    AgFns.refreshGrid(this.paFeatureGridOptions, []);
    AgFns.refreshGrid(this.paAddonGridOptions, []);
  }

  onPaAugCellValueChanged(event: CellValueChangedEvent) {
    if (!this.selectedPa) return;

    if (this.selectedPa.programAllowanceUserGroupMaps.length == 0) {
      this.toastr.warning('At least one user group must be selected.');
    }
  }

  async markError(errEnt: Entity, propName?: string) {
    const ent = errEnt as ProgramAllowance;
    AgFns.selectGridRowByKey(
      this.paGridOptions,
      (e: ProgramAllowance) => e.id,
      ent.id,
      propName
    );
  }

}
