/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AgFns,
  BaseService,
  ISortModel,
  ProxAgFns,
  SaveAndQueryComponent,
  StatusService,
  Roles
} from '@core';
import {
  AccountAddress,
  AccountUser,
  Gender,
  ProgramUserGroup,
  ProgramUserGroupMap,
  ProximityUser,
  ActiveStatus,
  ActiveStatusEnum,
  ShippingUserGroup,
  ShippingUserGroupMap,
  ProximityRightEnum,
  Account,
  ProximityRole
} from '@models';
import { AccountDbQueryService } from './../services/account-db-query.service';
import { AccountDbSaveService } from './../services/account-db-save.service';

import { UtilFns } from '@utils';
import { EntityError } from 'breeze-client';
import { DialogService } from 'primeng/dynamicdialog';
import { ColDef, GetRowIdParams, GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import { EntityFns } from '@data';
import { ProgramUserGroupFinderDialog } from './program-user-group-finder.dialog';
import { ShippingUserGroupFinderDialog } from './shipping-user-group-finder.dialog';

@Component({
  selector: 'prox-account-user-frm',
  templateUrl: './account-user-frm.component.html',
})
export class AccountUserFrmComponent extends SaveAndQueryComponent {
  accountId!: string;
  accountName!: string;
  accountUserId!: string;
  accountUser: AccountUser = new AccountUser();
  account!: Account;
  proximityUser: ProximityUser = new ProximityUser();
  activeStatuses: ActiveStatus[] = [];

  genders: Gender[] = [];
  programUserGroups: ProgramUserGroup[] = [];
  pugmGridOptions!: GridOptions;
  pugms: ProgramUserGroupMap[] = [];
  shippingUserGroups: ShippingUserGroup[] = [];
  sugmGridOptions!: GridOptions;
  sugms: ShippingUserGroupMap[] = [];
  formTitle = "* New Account User *";
  isBeingAdded!: boolean;
  allRoles: ProximityRole[] = [];
  accountUserRole?: ProximityRole;

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    override dbQueryService: AccountDbQueryService,
    override dbSaveService: AccountDbSaveService,
    public pngDialogService: DialogService,
    private statusService: StatusService

  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }
  
  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    this.accountUserId = params['accountUserId']
    UtilFns.assertNonEmptyString(this.accountId, 'accountId');
    UtilFns.assertNonEmptyString(this.accountUserId, "accountUserId");

    [this.account, this.genders, this.activeStatuses, this.programUserGroups, this.shippingUserGroups, this.allRoles] = await Promise.all([
      this.dbQueryService.getAccountById(this.accountId),
      this.dbQueryService.getAll(Gender),
      this.dbQueryService.getAll(ActiveStatus),
      this.dbQueryService.getProgramUserGroups(this.accountId),
      this.dbQueryService.getShippingUserGroups(this.accountId),
      this.dbQueryService.getAccountRolesByAccountId(this.accountId)
    ]);

    UtilFns.assertNonNull(this.account, 'Account');

    this.isBeingAdded = this.accountUserId === 'add';

    if (this.isBeingAdded) {
      this.createNewUser();
    } else {
      this.accountUser = await this.dbQueryService.getAccountUserById(this.accountUserId);
      this.proximityUser = this.accountUser?.proximityUser;
      this.formTitle = this.accountUser.proximityUser.fullName;
    }
    this.pugms = this.accountUser.programUserGroupMaps ?? [];
    this.sugms = this.accountUser.shippingUserGroupMaps ?? [];

    this.pugmGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPugmGridReady,
      rowModelType: 'clientSide',
      getRowId: (rowIdParams: GetRowIdParams) => {
        const pugm = rowIdParams.data as ProgramUserGroupMap;
        return pugm.programUserGroupId;
      },
    });

    this.sugmGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onSugmGridReady,
      rowModelType: 'clientSide',
      getRowId: (rowIdParams: GetRowIdParams) => {
        const sugm = rowIdParams.data as ShippingUserGroupMap;
        return sugm.shippingUserGroupId;
      },
    });

    // To test isFormDisabled logic
/*      if (this.accountUser.proximityUser.lastName.startsWith('C')) {
       this.isFormDisabled = true;
     } */

    this.setTitle("Account User for " + this.account.name);
    this.isPageReady = true;
  }

  isReadOnly() {
    return !this.hasRight(ProximityRightEnum.CanManageAccountOrganization) 
      || this.statusService.getWorkingStatus(this.account as any).isReadOnly;
  }

  statusMessage() {
    if (!this.hasRight(ProximityRightEnum.CanManageAccountOrganization)) {
      return "Not authorized";
    }

    if (this.account.getStatusId() == ActiveStatusEnum.Active) {
      return "Account is Active.  Account Users are Editable."
    }
    return this.statusService.getWorkingStatus(this.accountUser as any).statusDisplay;
  }

  okToDelete() {
    return !this.isReadOnly();
  }

  override get entity() { return this.accountUser };

  onPugmGridReady(event: GridReadyEvent) {
    const [colDefs, sortModel] = this.getPugmColDefsAndSortModel();
    AgFns.initGrid(event.context.gridOptions, colDefs, sortModel);
  }

  getPugmColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      ProxAgFns.getEntityStateColDef(),
      {
        headerName: 'Name',
        field: 'programUserGroup.name',
        filter: 'agTextColumnFilter',
        minWidth: 500
      },
      ProxAgFns.getEntityDeleteColDef(this.onPugmDelete.bind(this), { canDisplay: this.okToDelete.bind(this)}),
    ];
    const sortModel: ISortModel = [
      { colId: 'programUserGroup.name', sort: 'asc' },
      
    ];
    return [colDefs, sortModel] as const;
  }

  async onPugmAttach() {
    const usedPugIds = new Set(this.accountUser.programUserGroupMaps.map(x => x.programUserGroupId));
    const unusedProgramUserGroups = this.programUserGroups.filter(x => !usedPugIds.has(x.id));
    const newPugs = await this.dialogService.createFinder(ProgramUserGroupFinderDialog, {
      programUserGroups: unusedProgramUserGroups,
      rowSelection: 'multiple',
    }) ;
    const newPugms = newPugs.map(pug => {
      let pugm = this.dbSaveService.uow.undoIfDeleted(ProgramUserGroupMap, [ pug.id, this.accountUser.id]);
      if (pugm == null) {
        pugm = this.dbSaveService.createEntity(ProgramUserGroupMap, {
          programUserGroupId: pug.id,
          accountUserId: this.accountUser.id
        });
      }
      return pugm;
    });
    this.pugms = this.accountUser.programUserGroupMaps;
    AgFns.refreshGrid(this.pugmGridOptions, this.pugms);
  }

  async onPugmDelete(pugm: ProgramUserGroupMap) {
    EntityFns.deleteOrDetach(pugm.entityAspect);
    this.pugms = this.accountUser.programUserGroupMaps;
    AgFns.refreshGrid(this.pugmGridOptions, this.pugms);
  }

  onSugmGridReady(event: GridReadyEvent) {
    const [colDefs, sortModel] = this.getSugmColDefsAndSortModel();
    AgFns.initGrid(event.context.gridOptions, colDefs, sortModel);
  }

  getSugmColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      ProxAgFns.getEntityStateColDef(),
      {
        headerName: 'Name',
        field: 'shippingUserGroup.name',
        filter: 'agTextColumnFilter',
        maxWidth: 500
      },
      ProxAgFns.getEntityDeleteColDef(this.onSugmDelete.bind(this), { canDisplay: this.okToDelete.bind(this)}),
    ];
    const sortModel: ISortModel = [
      { colId: 'shippingUserGroup.name', sort: 'asc' },
      
    ];
    return [colDefs, sortModel] as const;
  }

  async onSugmAttach() {
    const usedSugIds = new Set(this.accountUser.shippingUserGroupMaps.map(x => x.shippingUserGroupId));
    const unusedShippingUserGroups = this.shippingUserGroups.filter(x => !usedSugIds.has(x.id));
    const newSugs = await this.dialogService.createFinder(ShippingUserGroupFinderDialog, {
      shippingUserGroups: unusedShippingUserGroups,
      rowSelection: 'multiple',
    }) ;
    const newSugms = newSugs.map(sug => {
      let sugm = this.dbSaveService.uow.undoIfDeleted(ShippingUserGroupMap, [ sug.id, this.accountUser.id]);
      if (sugm == null) {
        sugm = this.dbSaveService.createEntity(ShippingUserGroupMap, {
          shippingUserGroupId: sug.id,
          accountUserId: this.accountUser.id
        });
      }
      return sugm;
    });
    this.sugms = this.accountUser.shippingUserGroupMaps;
    AgFns.refreshGrid(this.sugmGridOptions, this.sugms);
  }

  async onSugmDelete(pugm: ProgramUserGroupMap) {
    EntityFns.deleteOrDetach(pugm.entityAspect);
    this.pugms = this.accountUser.programUserGroupMaps;
    AgFns.refreshGrid(this.pugmGridOptions, this.pugms);
    
  }

  /* statusMessage(): string {
    return <string>this.statusService.getWorkingStatus(this.accountUser as any).longDisplay;
  }

  async onChangeStatus() {
    const originalId =
      this.proximityUser.entityAspect?.originalValues['activeStatusId'] ??
      this.proximityUser.activeStatusId;
    const isAvailable = (s) => {
      if (
        s.id == (!ActiveStatusEnum.Active) &&
        this.proximityUser.entityAspect.entityState.isAdded()
      ) {
        return false;
      } else {
        return true;
      }
    };

    const selectedStatusId = await StatusChangeDialog.open(
      this.pngDialogService,
      {
        statuses: this.activeStatuses,
        isAvailable: isAvailable,
        currentStatus: this.accountUser.proximityUser.activeStatus
      },
      {
        header: 'Proximity User Status',
      }
    );

    if (selectedStatusId != null) {
      this.proximityUser.activeStatusId = selectedStatusId;
    }
  }; */

  onAddAddress(type: 'B' | 'S') {
    const addr = this.dbSaveService.createEntity(AccountAddress, {
      accountId: this.accountId,
      isBillingAddress: type == 'B',
      isShippingAddress: type == 'S',
      isPersonalAddress: true,
    });
    if (type == 'B') {
      this.accountUser.billingAccountAddressId = addr.id;
    } else {
      this.accountUser.shippingAccountAddressId = addr.id;
    }
  }

  override async addCrossValidationErrors()  {
    const ok = await this.dbQueryService.checkIfIsUnique(this.proximityUser, 'id', 'username');
    if (!ok) {
      this.createValidationError(this.proximityUser, 'username', 'This Login Name has already been taken.');
    }
  }

  override async beforeSave() {
    if (this.isBeingAdded) {
      this.proximityUser.proximityRole.name = Roles.AccountUser;
      this.proximityUser.entityAspect.setAdded();
      this.accountUser.entityAspect.setAdded();
    };
    this.formTitle = this.accountUser.proximityUser.fullName;
    return true;
  }

  override async afterUndo(): Promise<void> {
    if (this.isBeingAdded) {
      this.createNewUser();
    }
    this.pugms = this.accountUser.programUserGroupMaps;
    this.sugms = this.accountUser.shippingUserGroupMaps;
    AgFns.refreshGrid(this.pugmGridOptions, this.pugms);
    AgFns.refreshGrid(this.sugmGridOptions, this.sugms);

    // Undo operation should have already detached added records - so this shouldn't be needed
    // if (this.accountUser.billingAccountAddress.entityAspect.entityState.isAdded()) {
    //   this.accountUser.billingAccountAddress.entityAspect.setDetached();
    //   this.accountUser.billingAccountAddressId = undefined;
    // }
    // if (this.accountUser.shippingAccountAddress.entityAspect.entityState.isAdded()) {
    //   this.accountUser.shippingAccountAddress.entityAspect.setDetached();
    //   this.accountUser.shippingAccountAddressId = undefined;
    // }
  }

  override async afterSave() {
    this.isBeingAdded = false;

    // if saving changes to yourself, reload the page so AuthUser gets the changes
    if (this.proximityUser.id == this.authUser?.id || this.proximityUser.id == this.authUser?.actingAsUser?.id) {
      location.reload();
    }
  }

  private createNewUser() {
    const role = this.allRoles.find(p => p.name == Roles.AccountUser);
    this.proximityUser = this.dbSaveService.createEntity(ProximityUser, { activeStatusId: ActiveStatusEnum.Active, proximityRoleId: role?.id });
    this.accountUser = this.dbSaveService.createEntity(AccountUser, {
      id: this.proximityUser.id,
      accountId: this.accountId,
      proximityUserId: this.proximityUser.id,
      genderId: 0,
    });
    this.proximityUser.entityAspect.setUnchanged();
    this.accountUser.entityAspect.setUnchanged();
    this.accountUser.entityAspect.validateEntity();
    this.proximityUser.entityAspect.validateEntity();
  }
  // note simple snapshotting to detect changes to 'added' record will not work on this form because we of two reasons 
  //  1 - Address fields ( complex objects ) are not currently handled by snapshots 
  //  2 - We are actually editing two different objects here - AccountUser and its underlying ProximityUser

  override navigateToValidationError(ee: EntityError) {
    // TODO: need to change tab for accountAddress errors
    UtilFns.focusInputByEntity('#topLevel', ee.entity, ee.propertyName);
  }
}
