<div class="h-full flex flex-column">
  <div class="pb-2">
    <prox-breadcrumb></prox-breadcrumb>
  </div>

<!--   <div class="mt-2 pb-1">
    <h5><span style="color: red; margin-left: 5px;">Account Activation Checklist</span></h5>
    <p-panel header="Activation Editor for {{account?.name}}">
      <div class="mt-2 pb-3" style="height: 200px">
        <prox-ag-grid style="height: 100%; width: 100%;" [gridOptions]="errsGridOptions" [rowData]="activationErrors"></prox-ag-grid>
      </div>
       <div class="flex">
        <div class="mr-2">
          <button (click)="onOk()" pButton type="button" label="Ok" icon="pi pi-check" class="p-button-raised"></button>
        </div>
      </div>
    </p-panel>
  </div> -->

  <div class="flex-grow-1">
    <div class="layout-menu-container flex-grow-1 h-full flex gap-3">
      <p-menu *ngIf="items.length" styleClass="layout-submenu" [model]="items"></p-menu>
      <div class="card flex-grow-1">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>