import { Injectable } from '@angular/core';
import { Account, ActiveStatusEnum, ProgramPcardTypeEnum, ProgramPurchaseOrderTypeEnum } from '@models';
import * as _ from 'lodash-es';
import { AccountDbQueryService } from './account-db-query.service';
import { Router } from '@angular/router';
import { Roles } from '@core';
import { StringFns, UtilFns } from '@utils';

export interface ICheckListItem {
  index: number;
  description: string;
  pass: boolean;
  info: string | null;
  route: string;
}

@Injectable({
  providedIn: 'root',
})
export class AccountActivationService {
  checklist: ICheckListItem[] = [];
  private activatedAccount?: Account;
  private _activateErrors: ICheckListItem[] = [];

  constructor(private dbQuery: AccountDbQueryService, private router: Router) {
    this.loadChecklist();
  }

  loadChecklist() {
    this.checklist.push({
      index: 0,
      description: 'User Import Code requirement is required',
      pass: false,
      info: null,
      route: 'info',
    });

    this.checklist.push({
      index: 10,
      description: 'Principle Account Administrator is required, active and of type PrincipleAccountAdministrator',
      pass: false,
      info: null,
      route: 'info',
    });

    this.checklist.push({
      index: 11,
      description: 'Principle Account Administrators must have e-mail addresses',
      pass: false,
      info: null,
      route: 'admin',
    });

    this.checklist.push({
      index: 20,
      description: 'At least one Administrator Group is required',
      pass: false,
      info: null,
      route: 'ag',
    });

    this.checklist.push({
      index: 30,
      description: 'Each Administrator Group must have at least one Active Administrator',
      pass: false,
      info: null,
      route: 'ag',
    });

    this.checklist.push({
      index: 50,
      description: 'At least one Active Program User Group is required',
      pass: false,
      info: null,
      route: 'pug',
    });

    this.checklist.push({
      index: 60,
      description: 'Each Active Program User Group requires at least one Account User',
      pass: false,
      info: null,
      route: 'pug',
    });

    this.checklist.push({
      index: 70,
      description: 'Each Active Shipping User Group requires at least one Account User',
      pass: false,
      info: null,
      route: 'ship',
    });

    this.checklist.push({
      index: 80,
      description: 'Active Shipping User Group requires a purchase order',
      pass: false,
      info: null,
      route: 'ship',
    });
    this.checklist.push({
      index: 85,
      description: 'Active Shipping User Group requires a procurement card (p-card)',
      pass: false,
      info: null,
      route: 'ship',
    });

    this.checklist.push({
      index: 90,
      description: 'Active Program User Group requires a purchase order',
      pass: false,
      info: null,
      route: 'pug',
    });

    this.checklist.push({
      index: 95,
      description: 'Active Program User Group requires a procurement card (p-card)',
      pass: false,
      info: null,
      route: 'pug',
    });

    this.checklist.push({
      index: 110,
      description: 'At least one Proximity Organization Tree is required',
      pass: false,
      info: null,
      route: 'org',
    });

    this.checklist.push({
      index: 120,
      description: 'Each Proximity Organization Tree requires a top-level Admin Group',
      pass: false,
      info: null,
      route: 'org',
    });
  }

  routeTo(route: string) {
    if (route === 'info') {
      this.router.navigate(['/account/accounts', this.activatedAccount!.id, 'manage', 'account-info']);
    }

    if (route === 'ag') {
      this.router.navigate(['/account/accounts', this.activatedAccount!.id, 'manage', 'account-admin-groups']);
    }

    if (route === 'pug') {
      this.router.navigate(['/account/accounts', this.activatedAccount!.id, 'manage', 'program-user-groups']);
    }

    if (route === 'ship') {
      this.router.navigate(['/account/accounts', this.activatedAccount!.id, 'manage', 'shipping-user-groups']);
    }

    if (route === 'org') {
      this.router.navigate(['/account/accounts', this.activatedAccount!.id, 'manage', 'approval-trees']);
    }

    if (route == 'admin') {
      this.router.navigate(['/account/accounts', this.activatedAccount!.id, 'manage', 'account-administrators']);
    }
  }

  public async activate(accountId: string): Promise<boolean> {
    this.activatedAccount = await this.dbQuery.getAccountForValidationById(accountId);
    this.activateErrors = this.runTests();
    return this.activateErrors.length > 0;
  }

  get activateErrors(): ICheckListItem[] {
    return this._activateErrors;
  }

  set activateErrors(value: ICheckListItem[]) {
    this._activateErrors = value;
  }

  get isActivated(): boolean {
    return this.activateErrors.length > 0;
  }

  public deactivate() {
    this.activatedAccount = undefined;
    this.activateErrors = [];
  }

  private runTests(): ICheckListItem[] {
    this.checklist.forEach((p) => {
      p.pass = true;
      this.testItem(p);
    });
    return this.checklist.filter((p) => p.pass == false);
  }

  private testItem(checkItem: ICheckListItem) {
    if (!this.activatedAccount) return;

    if (checkItem.index == 0) {
      if (this.activatedAccount.accountImportCodeOptionId == null) {
        checkItem.pass = false;
      }
    }

    if (checkItem.index == 10) {
      if (this.activatedAccount.primaryAccountAdminId == null) {
        checkItem.pass = false;
      } else {
        if (
          this.activatedAccount.primaryAccountAdmin.getStatusId() != ActiveStatusEnum.Active ||
          this.activatedAccount.primaryAccountAdmin.getRoleName() != Roles.PrincipalAccountAdmin
        ) {
          checkItem.pass = false;
        }
      }
    }

    if (checkItem.index == 11) {
      let pass = true;

      this.activatedAccount.accountAdmins.forEach((r) => {
        if (
          r.proximityUser.activeStatusId === ActiveStatusEnum.Active &&
          r.proximityUser.proximityRole.name == Roles.PrincipalAccountAdmin &&
          StringFns.isNullEmptyOrWhiteSpace(r.proximityUser.email)
        ) {
          pass = false;
        }
      });

      checkItem.pass = pass;
    }

    if (checkItem.index == 20) {
      if (this.activatedAccount.accountAdminGroups.length == 0) {
        checkItem.pass = false;
      }
    }

    if (checkItem.index == 30) {
      let info = '';

      if (this.activatedAccount.accountAdminGroups.length > 0) {
        this.activatedAccount.accountAdminGroups.forEach((p) => {
          if (p.accountAdminGroupMaps.length == 0) {
            if (info == '') {
              info += p.name;
            } else {
              info += ' | ' + p.name;
            }
          }
        });

        if (info != '') {
          checkItem.info = 'Errors: ' + info;
          checkItem.pass = false;
        }
      }
    }

    if (checkItem.index == 50) {
      if (this.activatedAccount.programUserGroups.filter((p) => p.activeStatusId == ActiveStatusEnum.Active).length == 0) {
        checkItem.pass = false;
      }
    }

    if (checkItem.index == 60) {
      let info = '';
      const filtered = this.activatedAccount.programUserGroups.filter((p) => p.activeStatusId == ActiveStatusEnum.Active);

      if (filtered.length > 0) {
        filtered.forEach((p) => {
          if (p.programUserGroupMaps.length == 0) {
            if (info == '') {
              info += p.name;
            } else {
              info += ' | ' + p.name;
            }
          }
        });

        if (info != '') {
          checkItem.info = 'Errors: ' + info;
          checkItem.pass = false;
        }
      }
    }

    if (checkItem.index == 70) {
      let info = '';
      const filtered = this.activatedAccount.shippingUserGroups.filter((p) => p.activeStatusId == ActiveStatusEnum.Active);

      if (filtered.length > 0) {
        filtered.forEach((p) => {
          if (p.shippingUserGroupMaps.length == 0) {
            if (info == '') {
              info += p.name;
            } else {
              info += ' | ' + p.name;
            }
          }
        });

        if (info != '') {
          checkItem.info = 'Errors: ' + info;
          checkItem.pass = false;
        }
      }
    }

    if (checkItem.index == 80) {
      let info = '';
      this.activatedAccount.programs.forEach((p) => {
        if (p.programPurchaseOrderTypeId === ProgramPurchaseOrderTypeEnum.ByShippingUserGroup && p.isPurchaseOrderRequired) {
          this.activatedAccount!.shippingUserGroups.forEach((s) => {
            if (!s.accountBlanketPurchaseOrderId) {
              if (info == '') {
                info += s.name;
              } else {
                info += ' | ' + s.name;
              }
            }
          });
        }
      });
      if (info != '') {
        checkItem.info = 'Errors: ' + info;
        checkItem.pass = false;
      }
    }

    if (checkItem.index == 85) {
      let info = '';
      this.activatedAccount.programs.forEach((p) => {
        if (p.programPcardTypeId === ProgramPcardTypeEnum.ByShippingUserGroup && p.isPcardRequired) {
          this.activatedAccount!.shippingUserGroups.forEach((s) => {
            if (!s.accountProcurementCardId) {
              if (info == '') {
                info += s.name;
              } else {
                info += ' | ' + s.name;
              }
            }
          });
        }
      });
      if (info != '') {
        checkItem.info = 'Errors: ' + info;
        checkItem.pass = false;
      }
    }

    if (checkItem.index == 90) {
      let info = '';
      this.activatedAccount.programs.forEach((p) => {
        if (p.programPurchaseOrderTypeId === ProgramPurchaseOrderTypeEnum.ByProgramUserGroup && p.isPurchaseOrderRequired) {
          const programUserGroups = p.getProgramUserGroups();
          programUserGroups.forEach((s) => {
            if (!s.accountBlanketPurchaseOrderId) {
              if (info == '') {
                info += s.name;
              } else {
                info += ' | ' + s.name;
              }
            }
          });
        }
      });
      if (info != '') {
        checkItem.info = 'Errors: ' + info;
        checkItem.pass = false;
      }
    }

    if (checkItem.index == 95) {
      let info = '';
      this.activatedAccount.programs.forEach((p) => {
        if (p.programPcardTypeId === ProgramPcardTypeEnum.ByProgramUserGroup && p.isPcardRequired) {
          const programUserGroups = p.getProgramUserGroups();
          programUserGroups.forEach((s) => {
            if (!s.accountProcurementCardId) {
              if (info == '') {
                info += s.name;
              } else {
                info += ' | ' + s.name;
              }
            }
          });
        }
      });
      if (info != '') {
        checkItem.info = 'Errors: ' + info;
        checkItem.pass = false;
      }
    }

    if (checkItem.index == 110) {
      if (this.activatedAccount.approvalTrees.length == 0) {
        checkItem.pass = false;
      }
    }

    if (checkItem.index == 120) {
      let info = '';
      this.activatedAccount.approvalTrees.forEach((p) => {
        if (p.approvalTreeAdminGroups.length == 0) {
          if (info == '') {
            info += p.name;
          } else {
            info += ' | ' + p.name;
          }
        }
      });

      if (info != '') {
        checkItem.info = 'Errors: ' + info;
        checkItem.pass = false;
      }
    }
  }
}
