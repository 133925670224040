// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { AccountAdmin } from './account-admin';
import { AccountUser } from './account-user';
import { JobOrder } from './job-order';
import { Program } from './program';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class AccountUserAllowanceLog extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  get logType(): string {
    return 'ACCOUNT';
  }

  get logTrx(): string {
    if (this.amt < 0) {
      return 'Global Adj. to (+) Increase Your Allowance';
    } else {
      return 'Global Adj. to (-) Reduce Your Allowance';
    }
  }

  get debit(): number {
    if (this.amt > 0) {
      return this.amt;
    } else {
      return 0;
    }
  }

  get credit(): number {
    if (this.amt < 0) {
      return -this.amt;
    } else {
      return 0;
    }
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK accountAdmin */
  accountAdminId?: string;
  /** Guid FK accountUser */
  accountUserId!: string;
  /** String */
  adjustmentNote?: string;
  /** Decimal */
  amt!: number;
  /** Guid FK fromProgram */
  fromProgramId?: string;
  /** DateTimeOffset */
  logTs!: Date;
  /** Guid FK toJobOrder */
  toJobOrderId?: string;
  /** FK accountAdminId */
  accountAdmin!: AccountAdmin;
  /** FK accountUserId */
  accountUser!: AccountUser;
  /** FK fromProgramId */
  fromProgram!: Program;
  /** FK toJobOrderId */
  toJobOrder!: JobOrder;
}

