<div [class]="visible ? 'flex flex-column h-full' : 'hidden'">
  <div>
    <h5>Billing</h5>
  </div>
  <div class="h-full flex flex-column">
    <div class="flex flex-row gap-3">
      <div class="mb-3">
        <button [disabled]="parent.isReadOnly()" (click)="onSelectBillingAddress()" pButton type="button"
          label="Change Billing Address" icon="pi pi-plus" class="p-button-sm"></button>
      </div>

      <div>
        <button [disabled]="parent.isReadOnly()" (click)="addManifest()" pButton type="button"
        label="Add Manifest" class="p-button-sm"></button>
      </div>
    </div>
    <div class="flex" *ngIf="program.billingAccountAddress">
      <prox-address-frm [address]="program.billingAccountAddress" [disabled]="true"></prox-address-frm>
    </div>

    <div *ngIf="program.manifests.length > 0" class="flex-grow-1">
      <prox-ag-grid [gridOptions]="manifestGridOptions" [rowData]="program.manifests"></prox-ag-grid>
    </div>    

    <div *ngIf="selectedManifest != null" class="flex-grow-1">
      <prox-ag-grid [gridOptions]="supplierGridOptions" [rowData]="supplierChecklist"></prox-ag-grid>
    </div>    

  </div>
</div>