import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { AuthService, ImpersonationService, Roles } from '@core';
import { ProximityRightEnum } from '@models';
import { MenuItem } from 'primeng/api';

// CUSTOM 3/31/2024

@Component({
    selector: 'prox-app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    constructor(private impersonationService: ImpersonationService, private authService: AuthService) {}

    model: MenuItem[] = [];
    selectedIndex = 2;

    clicked(index: number) {
        this.clearAll();
        this.model[index].icon = this.model[index].icon + ' fa-fade';
        this.selectedIndex = index;
    }

    clearAll() {
        this.model.forEach(i => {
            i.icon = i.icon?.replace('fa-fade', '');
        });
    }

    ngOnInit() {
        const user = this.authService.getUser();
        this.model = [
            {
                label: 'Dashboard',
                icon: 'fa-sharp-duotone fa-solid fa-gauge-high',
                routerLink: ['/']
            },
            {
                label: 'Proximity Administration',
                icon: 'fa-duotone fa-thin fa-globe',
                routerLink: ['/global']
            },
            {
                label: 'Supplier Management',
                icon: 'fa-sharp-duotone fa-solid fa-truck-fast',
                routerLink: ['/supplier/suppliers'],
                visible: user?.hasRight(ProximityRightEnum.CanManageSupplierAdministration, ProximityRightEnum.CanManageSupplierPartnerships, ProximityRightEnum.CanManageSupplierProducts),
            },
            {
                label: 'Account Management',
                icon: 'fa-sharp-duotone fa-solid fa-users',
                routerLink: ['/account/accounts'],
                visible: user?.hasRight(ProximityRightEnum.CanManageAccountAdministration, ProximityRightEnum.CanManageAccountOrganization, 
                    ProximityRightEnum.CanEditProgram, ProximityRightEnum.CanCreateProgram)
            },
            {
                label: 'Orders & Returns',
                icon: 'fa-sharp-duotone fa-solid fa-cart-shopping',
                routerLink: ['/account-user/accounts'],
                visible: user?.hasRight(ProximityRightEnum.CanCreateJobOrders, ProximityRightEnum.CanEditJobOrderByApprovalTree)
            },
            {
                label: 'Approvals and User Management',
                icon: 'fa-sharp-duotone fa-solid fa-comments-question-check',
                routerLink: ['/account-admin/accounts'],
                visible: user?.hasRight(ProximityRightEnum.CanApproveJobOrderByApprovalTree, ProximityRightEnum.CanEditUserByApprovalTree, ProximityRightEnum.CanEditOwnData)
            },
            {
                label: 'Notifications',
                icon: 'pi pi-fw pi-building',
                routerLink: ['/account-user/inapp-notifications', user?.accountId],
                visible: true,
            },
            { separator: true },
            {
                label: 'Admin View',
                icon: 'pi pi-eye',
                visible: user?.hasRight(ProximityRightEnum.IsSuperUserForAccounts, ProximityRightEnum.IsSuperUserForSuppliers),
                items: [
                    {
                        label: 'Change Log',
                        icon: 'pi pi-list',
                        routerLink: ['/supplier/admin/changelog'],
                    },
                    {
                        label: 'Users',
                        icon: 'pi pi-user',
                        routerLink: ['/supplier/admin/userlist'],
                    },
                    {
                        label: 'Orders',
                        icon: 'pi pi-list',
                        routerLink: ['/account-user/orders'],
                    },
                    {
                        label: 'Impersonate',
                        icon: 'pi pi-user',
                        command: () => this.impersonationService.openDialog(),
                    },
                    {
                        label: 'API Status',
                        icon: 'pi pi-user',
                        routerLink: ['/account/admin/apisendstatus'],
                    },
                ]
            }
        ]
    }
}
