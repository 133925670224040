// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Invoice } from './invoice';
import { JobOrderHistDetail } from './job-order-hist-detail';
import { ReturnRequestDetail } from './return-request-detail';

/// <code-import> Place custom imports between <code-import> tags
import { DateFns } from '@utils'
import { CancellationRequestDetailStatusEnum, ReturnRequestDetailStatusEnum } from './enums';
import * as _ from 'lodash-es';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class InvoiceDetail extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  getReturnRequestQty(){
    return _.sumBy(this.returnRequestDetails, 'qty');
  }

  getLastRepairDate(): Date | null{
    if (this.jobOrderHistDetail.returnPolicy && this.jobOrderHistDetail.returnPolicy.allowRepair && this.jobOrderHistDetail.returnPolicy.repairDays && this.jobOrderHistDetail.returnPolicy.repairDays > 0) {
      return DateFns.dateAdd(this.invoice.shipTs, this.jobOrderHistDetail.returnPolicy.repairDays, 'days');
    }
    return null;
  }

  getLastReturnDate(): Date | null {
    if (this.jobOrderHistDetail.returnPolicy && this.jobOrderHistDetail.returnPolicy.allowReturn && this.jobOrderHistDetail.returnPolicy.returnDays && this.jobOrderHistDetail.returnPolicy.returnDays > 0) {
      return DateFns.dateAdd(this.invoice.shipTs, this.jobOrderHistDetail.returnPolicy.returnDays, 'days');
    }
    return null;
  }

  isReturnable(): boolean {
    const today = new Date();
    const lastReturnDate = this.getLastReturnDate();
    if (!lastReturnDate) {
      return false;
    }
    return (lastReturnDate > today) && ((this.shipQty - this.getReturnedQty()) > 0);
  }

  isRepairable(): boolean {
    const today = new Date();
    const lastRepairDate = this.getLastRepairDate();
    if (!lastRepairDate) {
      return false;
    }
    return (lastRepairDate > today) && ((this.shipQty - this.getReturnedQty()) > 0);
  }

  isReturnOrRepairable(): boolean {
    return (this.isReturnable() || this.isRepairable());
  }

  private getReturnedQty(): number {
    let _qty = 0;
    this.returnRequestDetails.forEach(p => {
      // rejected items may be 'rereturned'
      if (p.returnRequestDetailStatusId != ReturnRequestDetailStatusEnum.SupplierRejected) {
        _qty += p.qty
      }
    })
    return _qty;
  }

  getReturnOrRepairableQty(): number {
    if (!this.isReturnOrRepairable()) {
      return 0;
    }
    return this.shipQty - this.getReturnedQty(); 
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK invoice */
  invoiceId!: string;
  /** Guid FK jobOrderHistDetail */
  jobOrderHistDetailId!: string;
  /** Int32 */
  redistributeQty!: number;
  /** Int32 */
  shipQty!: number;
  /** FK invoiceId */
  invoice!: Invoice;
  /** FK jobOrderHistDetailId */
  jobOrderHistDetail!: JobOrderHistDetail;
  /** Inv FK invoiceDetailId */
  returnRequestDetails!: ReturnRequestDetail[];
}

