import { Entity, EntityKey } from 'breeze-client';
import { UnitOfWork } from './unit-of-work';
import chain from 'lodash-es/chain';

interface HasRowVersion {
  rowVersion: any;
}

export interface ITypedEntityByKeyResult<T extends Entity> {
  entity?: T;
  entityKey: EntityKey;
  fromCache: boolean;
}

export abstract class DbQueryService {
  cachedLookups = {};
  private isCached: boolean = false;
  registeredEntityTypes: { new(): Entity }[] = [];  

  constructor(public uow: UnitOfWork) { }

  async cacheAllLookups() {
    if (this.isCached) {
      return;
    }

    // cache a bunch of lookups

    const r = await this.uow.manager.executeQuery('lookups');
    this.isCached = true;
    return;
  }

  get metadataStore() {
    return this.uow.manager.metadataStore;
  }

  createQuery<T extends Entity>(type: new () => T, resourceName?: string) {
    return this.uow.createQuery(type, resourceName);
  }

  // used to get all of a type that has already been fetched into the entityManager cache
  // intended for different use case than getAllCached which is 
  getAlreadyFetched<T extends Entity>(type: new () => T) {
    const r = this.uow.getEntities(type);
    return r;
  }

  getEntityByKey<T>(type : new () => T, keyValues: any[]) {
    return this.uow.getEntityByKey(type, keyValues) as T;
  }
  
  getAllCached<T extends Entity>(type: new () => T) {
    const r = this.uow.getEntities(type);
    if (r.length == 0) {
      throw Error('Not a cached resource: ' + type.name);
    }
    return r;
  }

  async getAll<T extends Entity>(type: new () => T) {
    var r = await this.cacheAllLookups();
    return this.getAllCached(type);
  }

  // Not currently used 
  private cache<T extends Entity>(type: new () => T, keyProp: string, valueProp: string) {
    const typeName = type.prototype.entityType.shortName;
    let map = this.cachedLookups[typeName];
    if (map) {
      return map;
    }
    const r = this.uow.getEntities(type);
    map = chain(r)
      .keyBy(e => e[keyProp])
      .mapValues(valueProp)
      .value();
    this.cachedLookups[typeName] = map;
    return map;
  }

  lookup(typeName: string, id: any) {
    const map = this.cachedLookups[typeName];
    if (map == null) {
      return null;
    }
    return map[id];
  }

  clearEntities(type: new () => Entity) {
    this.uow.clearEntities(type);
  }

  clearRegisteredEntityTypes() {
    this.registeredEntityTypes.forEach(t => this.uow.clearEntities(t));
  }


  async getById<T extends Entity>(type: new () => T, id: any, checkLocalCacheFirst?: boolean) : Promise<ITypedEntityByKeyResult<T>> {
    return this.fetchEntityByKey(type, id, checkLocalCacheFirst);
  }
  
  async fetchEntityByKey<T extends Entity>(type: { new(): T; }, id: any, checkLocalCacheFirst?: boolean) : Promise<ITypedEntityByKeyResult<T>> {
    const r = await this.uow.fetchEntityByKey(type, id, checkLocalCacheFirst);
    return <ITypedEntityByKeyResult<T>> r;
  }

  async checkRowVersion(entity: Entity & HasRowVersion) {
    const rowVersion = entity.rowVersion;
    const r = await entity.entityAspect.entityManager!.fetchEntityByKey(entity.entityAspect.getKey());
    // r.entity will be null if the entity no longer exists.
    if (r.entity == null || entity.rowVersion != rowVersion) {
      throw 'optimistic concurrency error';
    }
  }

  // fieldKey is the real key field of entity, fieldsNames are list of fields that are the alternate keys
  async checkIfIsUnique<T extends Entity>(entity: T, fieldKey: string, ...fieldNames: string[]): Promise<boolean> {
    const type = entity.constructor as { new(): T; };
    const where = {};
    fieldNames.forEach(f => where[f] = entity[f]);
    const r = await this.uow.createQuery(type)
      .where(where)
      .take(2)
      .execute();

    return r.length == 0 ? true : r.length == 1 && r[0][fieldKey] === entity[fieldKey]
  }

  async checkIfInUse<T1 extends Entity, 
    T2 extends Entity, 
    K extends keyof T2>(entity: T1, dependentType: new () => T2, depFieldName: K): Promise<boolean> {
    const where = {};
    where[depFieldName as string] = entity['id'];
    const r = await this.uow.createQuery(dependentType)
      .where(where)
      .executeCount();

    return r > 0;
  }


}
