/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, GridOptions, GridReadyEvent, RowSelectedEvent } from '@ag-grid-community/core';
import {
  Component
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgFns, ISortModel, SaveAndQueryComponent } from '@core';
import { NotificationSubmission, NotificationType  } from '@models';
import { BaseService } from '@core';

import { DialogService } from 'primeng/dynamicdialog';

import * as moment from 'moment-mini';
import { DateFns } from '@utils';
import { AccountDbQueryService } from './services/account-db-query.service';
import { AccountDbSaveService } from './services/account-db-save.service';
import { NotificationTemplateDialogComponent } from './notifiications/notification-template-dialog.component';

@Component({
  selector: 'prox-notification-submission-status',
  templateUrl: './notification-submission-status.component.html',
})
export class NotificationSubmissionStatusComponent extends SaveAndQueryComponent {
  
  nsGridOptions: GridOptions = {};
  notificationSubmissions: NotificationSubmission[] = [];
  selectedSubmission?: NotificationSubmission;

  shouldShowErrorsOnly: boolean = false;
  afterDate!: Date;
  
  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    override dbQueryService: AccountDbQueryService, 
    override dbSaveService: AccountDbSaveService,
    public pngDialogService: DialogService
  ) {
    super(baseService, route, dbSaveService, dbQueryService );
  }

  override async updateFromParams(): Promise<void> {
    
    this.afterDate = DateFns.dateAdd(new Date(), -2, 'weeks');

    await this.dbQueryService.getAllCached(NotificationType);

    this.notificationSubmissions = await this.dbQueryService.getNotificationSubmissionsAfter(this.afterDate);

    this.nsGridOptions = AgFns.initGridOptions(this, {
      onRowSelected: this.onNsRowSelected,
      onGridReady: this.onNsGridReady
    });
    
    this.isPageReady = true;
  }

  onNsGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions;
    const colDefs: ColDef[] = [
      
      
      { headerName: 'Event/Template ',  filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          const ns = params.data as NotificationSubmission;
          if (ns == null) return;
          return ns.notificationTemplate.templateName ?? ns.notificationTemplate.notificationEvent.description;
      }},
      { headerName: 'Type ',  filter: 'agTextColumnFilter', field: 'notificationTemplate.notificationType.name', maxWidth: 70 },
      { headerName: 'Account', field: 'account.name',  filter: 'agTextColumnFilter',   },
      { headerName: 'Account User', field: 'proximityUser.name', filter: 'agTextColumnFilter', maxWidth: 150 },
      
      { headerName: 'Creation Ts', field: 'createdTs', filter: 'agDateColumnFilter', maxWidth: 130  },
      { headerName: 'Submission Ts', field: 'submissionTs', filter: 'agDateColumnFilter', maxWidth: 130  },
      { headerName: 'Expiration Ts', field: 'submissionTs', filter: 'agDateColumnFilter', maxWidth: 130  },
      {
        ...AgFns.createButtonProps(' ', this.onViewTemplate.bind(this), {
          label: 'View',
        }),
        width: 60,
        maxWidth: 60,
      },
      // { headerName: 'Query Context', field: 'queryContext', filter: 'agTextColumnFilter',   },
      { headerName: 'Error', field: 'submissionError', filter: 'agTextColumnFilter',   },
      
    ];
    const sortModel: ISortModel = [
      { colId: 'createdTs', sort: 'desc' },
    ]
    
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    AgFns.selectFirstRow(gridOptions);
  }

  async onViewTemplate(ns: NotificationSubmission) {
    const ok = await NotificationTemplateDialogComponent.openDialog(this.pngDialogService, { notificationTemplate: ns.notificationTemplate  },   );
  }
  
  async refresh() {
    this.notificationSubmissions = await this.dbQueryService.getNotificationSubmissionsAfter(this.afterDate);
    if (this.shouldShowErrorsOnly) {
      this.notificationSubmissions = this.notificationSubmissions.filter(x => x.submissionError != null);
    }
    AgFns.refreshGrid(this.nsGridOptions, this.notificationSubmissions);
  }

  async submitAllPending() {
    await this.wrapBusy(async () => {
      await this.dbSaveService.postPendingNotificationSubmissions();
      this.refresh();
    });
  }

  async onNsRowSelected(event: RowSelectedEvent) {
    const ss = <NotificationSubmission> event.data;
    if (ss == null) {
      return;
    }

    if (event.node.isSelected()) {
      this.selectedSubmission = ss;
    }

  }
  
  onView(row: NotificationSubmission) {
    // this.router.navigate(['supplier/supplier-add-edit', row.id]);
  }

  
}
