<div *ngIf="isPageReady" class="flex flex-column h-full w-full">
  <h2>Activation Errors for {{account!.name}}</h2>
  <div class="flex-grow-1 mt-2 pb-3">
    <prox-ag-grid [gridOptions]="gridOptions" [rowData]="activationService.activateErrors">
    </prox-ag-grid>
  </div>
  <div class="flex">
    <div class="mr-2">
      <button (click)="onOk()" pButton type="button" label="Ok" icon="pi pi-check" class="p-button-raised"></button>
    </div>
  </div>
</div>