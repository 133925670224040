import { Route } from '@angular/router';
import { accountCanActivateGuard } from './services/account-can-activate.guard';
import { canDeactivateGuard } from '@core';
import { AccountListComponent } from './account-list.component';
import { AccountManagerComponent } from './account-manager.component';
import { AccountUserFrmComponent } from './account-users/account-user-frm.component';
import { AccountUserListComponent } from './account-users/account-user-list.component';
import { AccountAdminListComponent } from './account-administrator/account-administrator-list.component';
import { AccountAdminFrmComponent } from './account-administrator/account-administrator-frm.component';
import { AccountFrmComponent } from './account-frm.component';
import { ProgramUserGroupsComponent } from './account-users/program-user-groups.component';
import { ProgramListComponent } from './programs/program-list.component';
import { ProgramFrmComponent } from './programs/program-frm.component';
import { ProximityRightEnum } from '@models';
import { ShippingAccountAddressesComponent } from './addresses/shipping-account-addresses.component';
import { AccountAddressFrmComponent  } from './addresses/account-address-frm.component';
import { PriceListsComponent } from './price-lists/price-lists.component';
import { ApprovalTreesComponent } from './approval-trees/approval-trees.component';
import { AccountAdminGroupsComponent } from './account-administrator/account-admin-groups.component';
import { BillingAccountAddressesComponent } from './addresses/billing-account-addresses.component';
import { AccountBlanketPurchaseOrdersComponent } from './purchase-orders/account-blanket-purchase-orders.component';
import { ShippingUserGroupsComponent } from './account-users/shipping-user-groups.component';
import { BudgetProductTypeTagsComponent } from './product-type-tags/budget-product-type-tags.component';
import { AccountBudgetsComponent } from './budgets/account-budgets.component';
import { ProgramChangeStatusFrmComponent } from './programs/program-change-status-frm.component';
import { ProgramProductTypeTagsComponent } from './product-type-tags/program-product-type-tags.component.';
import { ImportUsersComponent } from './import-users/import-users.component';
import { AccountNotificationTemplateListComponent } from './notifiications/account-notification-template-list.component';
import { ProgramNarrativeComponent } from './programs/program-narrative.component';
import { ApiSendStatusComponent } from './api-send-status.component';
import { AccountNotificationSettingsComponent } from './notifiications/account-notification-settings.component';
import { AccountProcurementCardsComponent } from './purchase-orders/account-procurement-cards.component';
import { AccountRoleListComponent } from './account-administrator/account-role-list.component';

export const accountRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'accounts',
    pathMatch: 'full',
  },
  {
    path: 'accounts',
    canActivate: [accountCanActivateGuard],
    component: AccountListComponent,
    title: 'Proximity Accounts'
  },
  {
    path: 'admin',
    canActivate: [accountCanActivateGuard],
    title: 'Proximity Account Admins',
    children: [
      {
        path: 'apisendstatus',
        canActivate: [accountCanActivateGuard],
        component: ApiSendStatusComponent,
        title: 'Proximity API'
      },
    ]
  },
  {
    path: 'account-add-edit/:accountId',
    canActivate: [accountCanActivateGuard],
    canDeactivate: [canDeactivateGuard],
    component: AccountFrmComponent,
    title: 'Proximity Account'
  },
  {
    path: 'accounts/:accountId',
    canActivate: [accountCanActivateGuard],
    children: [
      {
        path: '',
        redirectTo: 'manage',
        pathMatch: 'full'
      },
      {
        path: 'manage',
        canActivate: [accountCanActivateGuard],
        component: AccountManagerComponent,
        children: [
          {
            path: '',
            redirectTo: 'account-info',
            pathMatch: 'full',
          },
          {
            path: 'account-info',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: AccountFrmComponent,
            data: { rights: [ProximityRightEnum.CanManageAccountAdministration] },
            title: 'Proximity Account'
          },
          {
            path: 'account-administrators',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: AccountAdminListComponent,
            data: { rights: [ProximityRightEnum.CanManageAccountAdministration] },
            title: 'Proximity Account Admins'
          },
          {
            path: 'account-roles',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: AccountRoleListComponent,
            data: { rights: [ProximityRightEnum.CanManageAccountAdministration] },
            title: 'Proximity Account Roles'
          },
          {
            path: 'account-administrators/:accountAdminId',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: AccountAdminFrmComponent,
            data: { rights: [ProximityRightEnum.CanManageAccountAdministration] },
            title: 'Proximity Account Admin'
          },
          {
            path: 'account-admin-groups',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanManageAccountAdministration] },
            component: AccountAdminGroupsComponent,
            title: 'Proximity Account Admin Groups'
          },
          /*{
            path: 'account-actions',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanManageAccountAdministration] },
            component: ActionListComponent,
            title: 'Proximity Account Actions'
          },
          {
            path: 'account-actions/:accountActionId',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanManageAccountAdministration] },
            component: ActionFrmComponent,
            title: 'Proximity Account Action'
          },*/
          {
            path: 'account-blanket-purchase-orders',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanManageAccountOrganization] },
            component: AccountBlanketPurchaseOrdersComponent,
            title: 'Proximity Purchase Orders'
          },
          {
            path: 'account-procurement-cards',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanManageAccountOrganization] },
            component: AccountProcurementCardsComponent,
            title: 'Proximity Procurement Cards'
          },
          {
            path: 'account-users',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanManageAccountOrganization] },
            component: AccountUserListComponent,
            title: 'Proximity Account Users'
          },
          {
            path: 'account-notification-templates',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: AccountNotificationTemplateListComponent,
            data: { rights: [ProximityRightEnum.CanManageAccountOrganization] },
            title: 'Proximity Notification Templatess'
          },
          {
            path: 'account-notification-settings',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: AccountNotificationSettingsComponent,
            data: { rights: [ProximityRightEnum.CanManageAccountOrganization] },
            title: 'Proximity Notification Settings'
          },
          {
            path: 'account-users/import/:accountId',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanManageAccountOrganization] },
            component: ImportUsersComponent,
            title: 'Proximity Import Users'
          },
          {
            path: 'shipping-user-groups',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanManageAccountOrganization] },
            component: ShippingUserGroupsComponent,
            title: 'Proximity Shipping User Groups'
          },
/*           {
            path: 'shipping-user-groups/:groupId/:name',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanEditAnyAccountUser] },
            component: ImportUsersComponent,
          }, */
          {
            path: 'program-user-groups',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanEditProgram] },
            component: ProgramUserGroupsComponent,
            title: 'Proximity Program User Groups'
          },
          {
            path: 'approval-trees',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            data: { rights: [ProximityRightEnum.CanEditProgram] },
            component: ApprovalTreesComponent,
            title: 'Proximity Organization'
          },
          {
            path: 'shipping-account-addresses',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            data: { rights: [ProximityRightEnum.CanManageAccountOrganization] },
            component: ShippingAccountAddressesComponent,
            title: 'Proximity Shipping Addresses'
          },
          {
            path: 'billing-account-addresses',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            data: { rights: [ProximityRightEnum.CanManageAccountOrganization] },
            component: BillingAccountAddressesComponent,
            title: 'Proximity Billing Addresses'
          },
          {
            path: 'shipping-account-addresses/:accountAddressId/:addressType',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            data: { rights: [ProximityRightEnum.CanManageAccountOrganization] },
            component: AccountAddressFrmComponent,
            title: 'Proximity Shipping Address'
          },
          {
            path: 'billing-account-addresses/:accountAddressId/:addressType',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            data: { rights: [ProximityRightEnum.CanManageAccountOrganization] },
            component: AccountAddressFrmComponent,
            title: 'Proximity Billing Address'
          },
          {
            path: 'budget-product-type-tags',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            data: { rights: [ProximityRightEnum.CanManageAccountOrganization] },
            component: BudgetProductTypeTagsComponent,
            title: 'Proximity Budget Tags'
          },
          {
            path: 'program-product-type-tags',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            data: { rights: [ProximityRightEnum.CanManageAccountOrganization] },
            component: ProgramProductTypeTagsComponent,
            title: 'Proximity Product Tags'
          },
          {
            path: 'account-budgets',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            data: { rights: [ProximityRightEnum.CanManageAccountOrganization] },
            component: AccountBudgetsComponent,
            title: 'Proximity Budgets'
          },
          {
            path: 'account-price-lists',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            data: { rights: [ProximityRightEnum.CanManageAccountOrganization] },
            component: PriceListsComponent,
            title: 'Proximity Catalog'
          },
          {
            path: 'programs',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanEditProgram] },
            component: ProgramListComponent,
            title: 'Proximity Programs'
          },
          {
            path: 'programs/:programId',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanEditProgram] },
            component: ProgramFrmComponent,
            title: 'Proximity Program'
          },
          {
            path: 'programs/status/:programId',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanEditProgram] },
            component: ProgramChangeStatusFrmComponent,
            title: 'Proximity Program Status'
          },
          {
            path: 'programs/narrative/:programId',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanEditProgram] },
            component: ProgramNarrativeComponent,
            title: 'Proximity Narrative'
          },
          {
            path: 'account-users/:accountUserId',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: AccountUserFrmComponent,
            data: { rights: [ProximityRightEnum.CanEditUserByApprovalTree, ProximityRightEnum.CanManageAccountOrganization] },
            title: 'Proximity Account User'
          }
        ],
      },
    ],
  },
  
];
