// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { ShippingUserGroupAddressMap } from './shipping-user-group-address-map';

/// <code-import> Place custom imports between <code-import> tags
import { Validator } from 'breeze-client';
import { EntityPropertyAnnotation, EntityTypeAnnotation } from '../entity-type-annotations';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class AccountAddress extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static getEntityTypeAnnotation(): EntityTypeAnnotation {
    const propAnnotations = [
      new EntityPropertyAnnotation('email', {
        validators: [Validator.emailAddress()],
      }),
    ];

    return new EntityTypeAnnotation({
      validators: [],
      propertyAnnotations: propAnnotations,
    });
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId!: string;
  /** String(100) */
  attn?: string;
  /** String(100) */
  city!: string;
  /** String(100) */
  description?: string;
  /** String(100) */
  email?: string;
  /** String(4) */
  extension?: string;
  /** String(50) */
  fax?: string;
  /** Boolean */
  isBillingAddress!: boolean;
  /** Boolean */
  isPersonalAddress!: boolean;
  /** Boolean */
  isShippingAddress!: boolean;
  /** String(100) */
  line1!: string;
  /** String(100) */
  line2?: string;
  /** String(100) */
  line3?: string;
  /** String(100) */
  name!: string;
  /** String(10) */
  state!: string;
  /** String(50) */
  telephone?: string;
  /** Boolean */
  wasPopulatedFromUniformax?: boolean;
  /** String(10) */
  zipcode!: string;
  /** FK accountId */
  account!: Account;
  /** Inv FK shippingAccountAddressId */
  shippingUserGroupAddressMaps!: ShippingUserGroupAddressMap[];
}

