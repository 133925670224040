import { Injectable } from "@angular/core";
import { UnitOfWork } from "@data";
import { HelpItem, NotificationQueryEnum, ProximityRightEnum } from "@models";
import { MenuItem } from "primeng/api";
import { AuthService, AuthUser, Roles } from "../../services/auth.service";
import { TemplatesService } from "../../services/templates.service";


@Injectable({
  providedIn: 'root',
})
export class HelpService {
  private uow!: UnitOfWork;
  private cache: { [key: string]: HelpItem[] | undefined } = {};

  menuItems?: MenuItem[];

  constructor(private templatesService: TemplatesService, private authService: AuthService) {
    // this.menuItems = templatesService.makeMenuItems(...templatesService.getAccountMergeList(1));
  }

  /** Set the unit of work, if not already set.  Must call before getItems() */
  init(uow: UnitOfWork) {
    if (uow && !this.uow) {
      this.uow = uow;
    }
    return this;
  }

  /** Get the menu of template variables */
  async getMenuItems() {
    if (!this.menuItems) {
      const authUser = this.authService.getUser();
      const queryId = authUser?.supplierId ? NotificationQueryEnum.SupplierHelp : NotificationQueryEnum.AccountHelp;
      this.menuItems = await this.templatesService.getNotificationMenuItems(queryId);
    }
    return this.menuItems;
  }

  /** Return all HelpItems with the given key */
  async getItems(key: string) {
    let items = this.cache[key];
    if (!items) {
      const res = await this.uow.createQuery(HelpItem, 'HelpItemsMerged').where({ route: key }).execute();
      items = this.cache[key] = res;
    }
    return items;
  }

  /** Return the first HelpItem with the given key and field */
  async getItem(key: string, field: string) {
    const items = await this.getItems(key);
    const item = items.find(x => x.field == field);
    return item;
  }

  canEdit(): boolean {
    const user = this.authService.getUser();
    return user?.hasRight(ProximityRightEnum.CanEditAccountHelp, ProximityRightEnum.CanManageSupplierAdministration) || false;
  }

  /** Save new or existing HelpItem for the given content.  Creates account-specific content for an account administrator. */
  async save(key: string, field: string, content: string) {
    const user = this.authService.getUser() as AuthUser;
    if (!this.canEdit()) {
      return;
    }
    let item = await this.getItem(key, field);
    if (!item || item.accountId != user.accountId || item.supplierId != user.supplierId) {
      item = this.uow.createEntity(HelpItem, {
        accountId: user.accountId,
        field: field,
        html: content,
        route: key,
        supplierId: user.supplierId
      });
    } else {
      item.html = content;
    }
    await this.uow.commit([item]);
    this.cache[key] = undefined;
  }
}