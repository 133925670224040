/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, GetRowIdParams, GridOptions, GridReadyEvent, RowSelectedEvent } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgFns, ISortModel, ProxAgFns, SaveAndQueryComponent, StatusService } from '@core';

import { BaseService } from '@core';

import { UtilFns } from '@utils';

import * as _ from 'lodash';
import { EntityFns } from '@data';

import { EntityError } from 'breeze-client';
import { AccountAdminGroup, AccountAdminGroupMap, AccountAdmin, ApprovalTreeAdminGroup, ProximityRightEnum, Account, ActiveStatusEnum } from '@models';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountAdminFinderDialog } from './account-admin-finder.dialog';
import { AccountActivationService } from '../services/account-activation.service';

@Component({
  selector: 'prox-account-admin-groups',
  templateUrl: './account-admin-groups.component.html',
})
export class AccountAdminGroupsComponent extends SaveAndQueryComponent {
  // @ViewChild(BudgetItemsListComponent) budgetListItemsComponent!: BudgetItemsListComponent;

  supplierId!: string;
  accountId!: string;
  account!: Account;
  tabViewIndex = 0;
  aagGridOptions!: GridOptions;
  aags: AccountAdminGroup[] = [];
  selectedAag?: AccountAdminGroup;
  adminGridOptions!: GridOptions;
  admins: AccountAdmin[] = [];

  isValidateActivated = false;

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    private statusService: StatusService,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService,
    public activationService: AccountActivationService
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, 'accountId');
    this.account = await this.dbQueryService.getAccountById(this.accountId);
    UtilFns.assertNonNull(this.account, 'Account');

    this.aagGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onAagGridReady,
      onRowSelected: this.onAagRowSelected,
      rowModelType: 'clientSide',
      getRowId: (rowIdParams: GetRowIdParams) => {
        const aag = rowIdParams.data as AccountAdminGroup;
        return aag.id;
      },
    });

    this.isValidateActivated = this.activationService.isActivated;
    if (this.isValidateActivated) {
      this.activationService.activate(this.account.id);
    }

    AgFns.captureGridRouteParams(this.aagGridOptions, this.route, 'id');

    this.adminGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onAdminGridReady,
      rowModelType: 'clientSide',
      getRowId: (rowIdParams: GetRowIdParams) => {
        const admin = rowIdParams.data as AccountAdmin;
        return admin.id;
      },
    });

    this.aags = await this.dbQueryService.getAccountAdminGroups(this.accountId);
    this.setTitle('Account Administrator Groups for ' + this.account.name);
    this.isPageReady = true;
  }

  showDelete() {
    return !this.isReadOnly();
  }

  isReadOnly() {
    return (
      !this.hasRight(ProximityRightEnum.CanManageAccountAdministration) ||
      this.statusService.getWorkingStatus(this.account as any).isActiveReadOnly
    );
  }

  statusMessage() {
    if (!this.hasRight(ProximityRightEnum.CanManageAccountAdministration)) {
      return 'Not authorized';
    }
    let msg = '';
    if (this.account?.activeStatusId == ActiveStatusEnum.Active) {
      return 'Account is Active and Not Editable.  To edit, set Account status to Hold.';
    } else if (this.account?.activeStatusId == ActiveStatusEnum.Hold) {
      msg = 'Account is On-Hold and is Editable';
    } else {
      return 'Account is ' + this.account?.activeStatus.name + ' and is Not Editable';
    }
    return msg;
  }

  // Aag Grid

  async onAagGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions as GridOptions;
    const [colDefs, sortModel] = this.getAagColDefsAndSortModel();
    this.updateAagMasterDetail(gridOptions);
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    await AgFns.refreshGrid(this.aagGridOptions, this.aags);
    AgFns.applyGridRouteParams(this.aagGridOptions);
  }

  getAagColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      ProxAgFns.getEntityStateColDef(),
      { field: 'name', editable: !this.isReadOnly(), filter: 'agTextColumnFilter', cellRenderer: 'agGroupCellRenderer' },
      ProxAgFns.getEntityDeleteColDef(this.onAagDelete.bind(this), { canDisplay: this.showDelete.bind(this) }),
    ];
    const sortModel: ISortModel = [{ colId: 'name', sort: 'asc' }];
    return [colDefs, sortModel] as const;
  }

  updateAagMasterDetail(parentGridOptions: GridOptions) {
    parentGridOptions.masterDetail = true;
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      { field: 'accountUser.proximityUser.lastName', headerName: 'Last Name', editable: false, filter: 'agTextColumnFilter' },
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      refreshStrategy: 'everything',
      getDetailRowData: (params) => {
        const aagms = params.data as AccountAdminGroupMap[];
        params.successCallback(aagms.map((augum) => augum.accountAdmin));
      },
    };
  }

  onAagRowSelected(event: RowSelectedEvent) {
    if (!event.node.isSelected()) return;
    const aag = event.data as AccountAdminGroup;
    if (aag == null) return;
    this.selectedAag = aag;
    if (aag.entityAspect.entityState.isDeleted()) {
      this.selectedAag = undefined;
      this.aagGridOptions.api?.deselectAll();
      return;
    }
    this.updateGridRouteParams(this.aagGridOptions, aag.id);
    this.admins = aag.accountAdminGroupMaps.map((x) => x.accountAdmin);
    AgFns.refreshGrid(this.adminGridOptions, this.admins);
  }

  async onAagAdd() {
    const newAag = this.dbSaveService.createEntity(AccountAdminGroup, { accountId: this.accountId });
    newAag.entityAspect.validateEntity();
    this.aags?.push(newAag);
    AgFns.refreshGrid(this.aagGridOptions, this.aags || []);
    const gridApi = this.aagGridOptions.api;
    if (!gridApi) return;
    const rowIndex = AgFns.getRowIndex(gridApi, newAag.id);
    this.aagGridOptions.api?.setFocusedCell(rowIndex ?? 0, 'name');
  }

  async onAagDelete(aag: AccountAdminGroup) {
    const inUse = await this.dbQueryService.checkIfInUse(aag, ApprovalTreeAdminGroup, 'accountAdminGroupId');
    if (inUse) {
      this.toastr.warning(`You cannot delete an Account Admin Group that is in use.`, 'Cannot Delete');
      return;
    }

    // if it's an added record - cascade delete won't catch them.
    if (aag.entityAspect.entityState.isAdded()) {
      aag.accountAdminGroupMaps.slice().forEach((x) => EntityFns.deleteOrDetach(x.entityAspect));
    }
    EntityFns.deleteOrDetach(aag.entityAspect);
    this.selectedAag = undefined;
    this.admins = [];
    if (this.aags) {
      _.remove(this.aags, aag);
      AgFns.refreshGrid(this.aagGridOptions, this.aags);
    }
  }

  // User Grid

  onAdminGridReady(event: GridReadyEvent) {
    const [colDefs, sortModel] = this.getAdminColDefsAndSortModel();
    AgFns.initGrid(event.context.gridOptions, colDefs, sortModel);
  }

  getAdminColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      ProxAgFns.getEntityStateColDef(),
      { headerName: 'Last Name', field: 'proximityUser.lastName', filter: 'agTextColumnFilter' },
      { headerName: 'First Name', field: 'proximityUser.firstName', filter: 'agTextColumnFilter' },
      { headerName: 'Middle Name', field: 'proximityUser.middleName' },
      { headerName: 'E-mail', field: 'proximityUser.email', filter: 'agTextColumnFilter' },
      ProxAgFns.getWorkingStatusDef('Status', this.statusService),
      ProxAgFns.getEntityDeleteColDef(this.onAdminDelete.bind(this)),
    ];
    const sortModel: ISortModel = [
      { colId: 'proximityUser.lastName', sort: 'asc' },
      { colId: 'proximityUser.firstName', sort: 'asc' },
    ];
    return [colDefs, sortModel] as const;
  }

  async onAdminAdd() {
    const newAdmins = await this.createAccountAdminGroupMaps();
    if (newAdmins.length == 0) return;
    this.admins.push(...newAdmins);
    AgFns.refreshGrid(this.adminGridOptions, this.admins);
    AgFns.updateMasterDetail(this.aagGridOptions, this.selectedAag);
  }

  async createAccountAdminGroupMaps() {
    if (!this.selectedAag) return [];
    const currentAdminSet = new Set(this.selectedAag.accountAdminGroupMaps.map((x) => x.accountAdmin));
    const allAccountAdmins = await this.dbQueryService.getAccountAdminsByAccountId(this.accountId);
    const remainingAccountAdmins = allAccountAdmins.filter((au) => !currentAdminSet.has(au));
    const accountAdmins = await this.dialogService.createFinder(AccountAdminFinderDialog, {
      accountAdmins: remainingAccountAdmins,
      rowSelection: 'multiple',
    });
    if (accountAdmins.length == 0) return [];

    const aag = this.selectedAag;
    const newAdminGroupMaps = accountAdmins.map((admin) => {
      let aagMap = this.dbSaveService.uow.undoIfDeleted(AccountAdminGroupMap, [aag.id, admin.id]);
      if (aagMap == null) {
        aagMap = this.dbSaveService.createEntity(AccountAdminGroupMap, { accountAdminGroupId: aag.id, accountAdminId: admin.id });
      }
      return aagMap;
    });
    const newAdmins = newAdminGroupMaps.map((x) => x.accountAdmin);
    return newAdmins;
  }

  async onAdminDelete(admin: AccountAdmin) {
    if (!this.selectedAag) return;
    const adminMap = this.selectedAag.accountAdminGroupMaps.find((x) => x.accountAdminId == admin.id);
    if (adminMap == null) return;
    EntityFns.deleteOrDetach(adminMap.entityAspect);
    _.remove(this.admins, admin);
    AgFns.refreshGrid(this.adminGridOptions, this.admins);
  }

  // -----------------------------------------------------------------------

  override async addCrossValidationErrors() {
    EntityFns.checkForDupErrors(
      this.aags,
      (x) => x.name,
      (e, dupName) => this.createValidationError(e, 'name', `This user group name is a duplicate: '${dupName}.`)
    );
  }

  override async afterSave() {
    if (this.isValidateActivated) {
      if (!await this.activationService.activate(this.account.id)) {
        this.account.activeStatusId = ActiveStatusEnum.Active;
        this.activationService.deactivate();
        this.isValidateActivated = false;
        await this.dbSaveService.saveChanges();
        await this.dialogService.okDialog('Activation Status', "Account is successfully updated to 'Active'");
      }
    }

    return this.afterUndo();
  }

  override async afterUndo() {
    if (this.aags) {
      this.aags = [];
    }

    this.aags = await this.dbQueryService.getAccountAdminGroups(this.accountId);
    this.selectedAag = _.first(this.aagGridOptions.api?.getSelectedRows());
    AgFns.updateMasterDetail(this.aagGridOptions, this.selectedAag);
    this.admins = this.selectedAag?.accountAdminGroupMaps.map((x) => x.accountAdmin) ?? [];

    AgFns.refreshGrid(this.aagGridOptions, this.aags);
    AgFns.refreshGrid(this.adminGridOptions, this.admins);
  }

  override async navigateToValidationError(error: EntityError) {
    const errEnt = error.entity;
    if (errEnt instanceof AccountAdminGroup) {
      AgFns.selectGridRowByKey(this.aagGridOptions, (e: AccountAdminGroup) => e.id, errEnt.id, error.propertyName);
    }
  }
}
