<prox-main  [parent]="this" [bodyTemplate]="bodyTemplate"></prox-main>

<ng-template #bodyTemplate>
  <div class="flex gap-3">
      <label class="mt-2">Template Type</label>
      <p-dropdown [options]="templateTypes" [(ngModel)]="templateType" (onChange)="onTemplateTypeChange()"
        optionLabel="name"  name="templateType"></p-dropdown>
    
    <button (click)="onAdd()" [disabled]="!canAdd()" pButton type="button" label="Add Template" icon="pi pi-plus"
      class="p-button-sm ml-3"></button>
    <button (click)="onCopy()" [disabled]="!canCopy()" pButton type="button" label="Copy Template" icon="pi pi-copy"
      class="p-button-sm ml-3"></button>
    <p-fileUpload *ngIf="isSuperUser" mode="basic" name="uploadTemplates" chooseIcon="pi pi-upload" accept="application/json" [maxFileSize]="1000000"
      (uploadHandler)="uploadHander($event)" [auto]="true" [customUpload]="true" chooseLabel="Upload Templates"
      class="inlinexxx-flex" styleClass="p-button-sm ml-3" />
    <button *ngIf="isSuperUser" (click)="downloadSelected()" pButton type="button" label="Download Selected Templates" icon="pi pi-download"
      class="p-button-sm ml-3"></button>
  </div>

  <div class="flex-grow-1" *ngIf="isPageReady">
    <div class="pt-2 pb-2 w-full h-full">
      <prox-ag-grid [gridOptions]="gridOptions" [rowData]="templates"></prox-ag-grid>
    </div>
  </div>
  <p-confirmDialog></p-confirmDialog>
</ng-template>
