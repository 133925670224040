import { Env } from "./env";

export const environment: Env = {
    production: true,
    apiUrl: 'https://proximity-api.ideablade.com/api/',
    adaptUrl: 'https://proximity-api.ideablade.com/api/Adapt',
    adminUrl: 'https://proximity-api.ideablade.com/api/Admin',
    accountUrl: 'https://proximity-api.ideablade.com/api/Account',
    supplierUrl: 'https://proximity-api.ideablade.com/api/Supplier',
    globalUrl: 'https://proximity-api.ideablade.com/api/Global',
    transactionUrl: 'https://proximity-api.ideablade.com/api/Transaction',
    ruleUrl: 'https://proximity-api.ideablade.com/api/Rule'

}
  
