// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { CancellationReasonType } from './cancellation-reason-type';
import { CancellationRequest } from './cancellation-request';
import { CancellationRequestDetailStatus } from './cancellation-request-detail-status';
import { JobOrderHistDetail } from './job-order-hist-detail';
import { CancellationReasonTypeEnum } from './enums';
import { CancellationRequestDetailStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class CancellationRequestDetail extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** String */
  cancellationExplanation?: string;
  /** Enum FK cancellationReasonType */
  cancellationReasonTypeId!: CancellationReasonTypeEnum;
  /** Enum FK cancellationRequestDetailStatus */
  cancellationRequestDetailStatusId!: CancellationRequestDetailStatusEnum;
  /** Guid FK cancellationRequest */
  cancellationRequestId!: string;
  /** String */
  cancellationStatusExplanation?: string;
  /** DateTimeOffset */
  cancellationStatusTs!: Date;
  /** Guid FK jobOrderHistDetail */
  jobOrderHistDetailId!: string;
  /** Int32 */
  qty!: number;
  /** Binary */
  rowVersion!: any;
  /** FK cancellationReasonTypeId */
  cancellationReasonType!: CancellationReasonType;
  /** FK cancellationRequestId */
  cancellationRequest!: CancellationRequest;
  /** FK cancellationRequestDetailStatusId */
  cancellationRequestDetailStatus!: CancellationRequestDetailStatus;
  /** FK jobOrderHistDetailId */
  jobOrderHistDetail!: JobOrderHistDetail;
}

