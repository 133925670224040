/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, GridApi, GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgFns, ISortModel, ProxAgFns, SaveAndQueryComponent, StatusChangeDialog, StatusService } from '@core';
import { Account, ActiveStatus, ActiveStatusEnum } from '@models';
import { BaseService } from '@core';
import { AccountDbQueryService } from './services/account-db-query.service';
import { AccountDbSaveService } from './services/account-db-save.service';
import { DialogService } from 'primeng/dynamicdialog';
import { AccountActivationService } from './services/account-activation.service';

export interface IActivationErrorParams {
  account: Account;
}

export enum AccountListEnum {
  Accounts,
  Approvals,
  Orders
}

@Component({
  selector: 'prox-account-list',
  templateUrl: './account-list.component.html',
})
export class AccountListComponent extends SaveAndQueryComponent {
  accounts: Account[] = [];
  activeStatuses: ActiveStatus[] = [];
  isActivating = false;
  listType!: AccountListEnum;
  //selectedRow!: Account;
  @ViewChild('actionCell') actionCell!: TemplateRef<any>;

  //selected: Account | null = null;
  actionSidebar: any;
  gridOptions: GridOptions = {};
  gridApi: GridApi | undefined;
  frameworkComponents: any;

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    override dbQueryService: AccountDbQueryService,
    override dbSaveService: AccountDbSaveService,
    private statusService: StatusService,
    public pngDialogService: DialogService,
    public activationService: AccountActivationService
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    this.accounts = await this.dbQueryService.createQuery(Account).execute();
    this.activeStatuses = await this.dbQueryService.getAll(ActiveStatus);
    this.gridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onGridReady,
    });
    this.activationService.deactivate();

    if (this.router.url == '/account-admin/accounts') {
      this.listType = AccountListEnum.Approvals;
    } else if (this.router.url == '/account-user/accounts') {
      this.listType = AccountListEnum.Orders;
    } else {
      this.listType = AccountListEnum.Accounts;
    }

    this.isPageReady = true;
  }

  onGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions;
    const [colDefs, sortModel] = this.getColDefsAndSortModel();
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    AgFns.selectFirstRow(gridOptions);
  }

  getColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      /*       { ...AgFns.createButtonProps('', this.onEdit.bind(this), { label: 'Edit'}), width: 50, maxWidth: 50 }, */
      { ...AgFns.createButtonProps('', this.onManage.bind(this), { label: 'Manage' }), width: 70, maxWidth: 70 },
      { headerName: 'Account Name', field: 'name', maxWidth: 300, filter: 'agTextColumnFilter' },
      {
        headerName: 'Primary Administrator',
        valueGetter: (c) =>
          c.data.primaryAccountAdmin
            ? (c.data?.primaryAccountAdmin?.proximityUser?.salutation
                ? c.data?.primaryAccountAdmin?.proximityUser?.salutation?.trim() + ' '
                : '') +
              c.data?.primaryAccountAdmin?.proximityUser?.firstName?.trim() +
              ' ' +
              c.data?.primaryAccountAdmin?.proximityUser?.lastName?.trim()
            : '',
        maxWidth: 300,
      },
      { headerName: 'E-mail', field: 'primaryAccountAdmin.proximityUser.email', maxWidth: 200 },
      { headerName: 'Telephone', field: 'primaryAccountAdmin.proximityUser.telephoneNumber', maxWidth: 200 },
      { headerName: 'Extension', field: 'primaryAccountAdmin.proximityUser.extension', maxWidth: 100 },
      { headerName: 'Cell', field: 'primaryAccountAdmin.proximityUser.cellNumber', maxWidth: 140 },
      { headerName: 'Status', field: 'activeStatus.name', maxWidth: 70 },
      ProxAgFns.getEntityStatusColDef(this.onChangeStatus.bind(this), { canDisplay: this.isEditable.bind(this) }),
    ];
    const sortModel: ISortModel = [{ colId: 'name', sort: 'asc' }];
    return [colDefs, sortModel] as const;
  }

  isEditable() {
    return this.listType === AccountListEnum.Accounts;
  }

  async onChangeStatus(row: Account) {
    const selectedStatusId = await StatusChangeDialog.open(
      this.pngDialogService,
      {
        statuses: this.activeStatuses,
        isAvailable: (s) => true,
        currentStatus: row.activeStatus,
      },
      {
        header: 'Account Status',
      }
    );

    if (selectedStatusId == row.activeStatusId) {
      return;
    }

    if (selectedStatusId == ActiveStatusEnum.Active) {
      if (row.activeStatusId != ActiveStatusEnum.Hold) {
        this.dialogService.okDialog('Cannot Change Status', "You must first set status to 'Hold' before activation");
        return;
      }

      if (row.activeStatusId === ActiveStatusEnum.Hold) {
        if (await this.activationService.activate(row.id)) {
          const ok = await this.dialogService.askIfOk(
            'Cannot Activate',
            'Account cannot be activated.  Switch to Account Activation Mode for fixes?'
          );
          if (ok) {
            this.onManage(row);
            return;
          } else {
            this.activationService.deactivate();
            return;
          }
        }
      }

      /*       const accountForActivation = await this.dbQueryService.getAccountForValidationById(row.id);
      this.activationService.errors = this.activationService.activate(accountForActivation);
      if (this.activationService.errors.length > 0) {
        const ok = await this.dialogService.askIfOk(
          'Cannot Activate',
          'Account cannot be activated.  Switch to Account Activation Mode for fixes?'
        );
        if (ok) {
          this.onManage(row);
          return;
        } else {
          this.activationService.deactivate();
          return;
        }
      } */
    }

    if (selectedStatusId != null && selectedStatusId) {
      row.activeStatusId = selectedStatusId;
      this.dialogService.verifyProceedDialog(
        this,
        this.statusService.getStatusExplanation(selectedStatusId, 'Account'),
        'Account Status Change'
      );
    }
  }

  async proceedDialog() {
    await this.dbSaveService.saveChanges();
    this.toastr.success('Status change updated', 'Database Activity');
    this.gridOptions.api?.refreshCells();
  }

  cancelDialog() {
    this.dbSaveService.rejectChanges();
    this.gridOptions.api?.refreshCells();
  }

  get isAccountUser() {
    return this.router.url.includes('/account-user/');
  }

  get title() {
    if (this.router.url == '/account-admin/accounts') {
      return 'Approvals and User Management - Accounts';
    } else if (this.router.url == '/account-user/accounts') {
      return 'Orders, Return and Cancellations - Accounts';
    } else {
      return 'Global Administration - Accounts ';
    }
  }

  onManage(row: Account) {
    if (this.router.url == '/account-admin/accounts') {
      this.router.navigate(['account-admin/accounts', row.id, 'manage', { relativeTo: this.route, queryParams: { isActivating: true } }]);
    } else if (this.router.url == '/account-user/accounts') {
      this.router.navigate(['account-user/accounts', row.id, 'manage']);
    } else {
      this.router.navigate(['account/accounts', row.id, 'manage']);
    }
  }

  onEdit(row: Account) {
    this.router.navigate(['account/account-add-edit', row.id]);
  }

  onAdd() {
    //this.router.navigate(['add'], { relativeTo: this.route })
    this.router.navigate(['account/account-add-edit', 'add']);
  }
}
