import { Component, Input } from '@angular/core';
import { UnitOfWork } from '@data';
import { ProximityAreaEnum, ProximityRight, ProximityRole, ProximityRoleRightMap } from '@models';
import { EntityState } from 'breeze-client';
import * as _ from 'lodash';

interface RightVm {
  right: ProximityRight
  hasRight: boolean,
  entity?: ProximityRoleRightMap
}

/** Handles checkboxes for setting role rights.  Saving must be handled by parent form. */
@Component({
  selector: 'prox-role-rights-frm',
  templateUrl: './role-rights-frm.component.html',
})
export class RoleRightsFrmComponent {
  @Input() areaId!: ProximityAreaEnum;
  @Input() allRights!: ProximityRight[];
  @Input() uow!: UnitOfWork;
  @Input() isReadOnly!: boolean;
  @Input() set proximityRole(val: ProximityRole) {
    this._proximityRole = val;
    setTimeout(() => this.initRole(), 0);
  }
  get proximityRole() {
    return this._proximityRole;
  }

  private _proximityRole!: ProximityRole;
  isPageReady = false;
  rights!: ProximityRight[];
  roleRights: RightVm[] = [];

  async initRole(): Promise<void> {
    this.isPageReady = false;
    if (!this._proximityRole || !this.allRights || !this.areaId) { return; }

    const roleRights = this._proximityRole.proximityRoleRightMaps;
    this.rights = this.allRights.filter(r => r.areaId == this.areaId);
    this.roleRights = this.rights.map(r => {
      const entity = roleRights.find(u => u.proximityRightId === r.id);
      return {
        right: r,
        hasRight: !!entity,
        entity
      }
    });

    this.isPageReady = true;

  }

  // not needed because we only show one area
  // private groupRightsIntoAreas() {
  //   this.areaNames = [];
  //   this.areaRights = {};
  //   const roleRights = this._proximityRole.proximityRoleRightMaps;
  //   this.rights = this.allRights.filter(r => this.areas.includes(r.areaId));
  //   this.areas.forEach(a => {
  //     const ar = this.rights.filter(r => r.areaId == a).map(r => {
  //       const entity = roleRights.find(u => u.proximityRightId === r.id);
  //       return {
  //         right: r,
  //         hasRight: !!entity,
  //         entity
  //       }
  //     });
  //     if (ar.length) {
  //       const areaName = ProximityAreaEnum[a];
  //       this.areaNames.push(areaName);
  //       this.areaRights[areaName] = ar;
  //     }
  //   });
  // }

  formatName(name: string) {
    return _.startCase(name);
  }

  isDisabled() {
    return this.isReadOnly;
  }

  toggleRight(vm: RightVm) {
    if (vm.hasRight) {
      if (vm.entity && vm.entity.entityAspect.entityState.isDeleted()) {
        // user previously had right; was removed and re-added
        vm.entity.entityAspect.setEntityState(EntityState.Unchanged);
      } else {
        vm.entity = this.uow.createEntity(ProximityRoleRightMap, { proximityRightId: vm.right.id, proximityRoleId: this.proximityRole.id });
      }
    } else {
      if (vm.entity) {
        vm.entity.entityAspect.setDeleted();
      }
    }
  }
}
