import { ColDef, GetRowIdParams, GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import { Component, OnInit } from '@angular/core';
import { AgFns, BusyService, ISortModel, ProxAgFns } from '@core';
import { Account } from '@models';
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
import { IActivationErrorParams } from './account-list.component';
import { AccountActivationService, ICheckListItem } from './services/account-activation.service';
import { UtilFns } from '@utils';

@Component({
  selector: 'prox-account-activate.dialog',
  templateUrl: './account-activate.dialog.component.html',
})
export class AccountActivateDialogComponent implements OnInit {
  isPageReady = false;
  gridOptions: GridOptions = {};
  account?: Account;
  //activationErrors: ICheckListItem[] = [];

  constructor(
    public activationService: AccountActivationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<IActivationErrorParams>,
    public busyService: BusyService
  ) {}

  async ngOnInit() {
    const params = this.config.data;
    UtilFns.assertNonNull(params);
    this.account = params?.account;
    //this.activationErrors = this.accountActivationService.activate(this.account);

    this.gridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onGridReady,
      getRowId: (rowIdParams: GetRowIdParams) => {
        const idx = rowIdParams.data as ICheckListItem;
        return idx.index.toString()
    }});

    this.isPageReady = true;
  }

  public static async openDialog(dialogService: DialogService, data?: IActivationErrorParams, config?: DynamicDialogConfig) {
    const ref = dialogService.open(AccountActivateDialogComponent, {
      closable: false,
      draggable: true,
      width: '80vh',
      height: '80vh',
      // baseZIndex: 10000,
      ...config,
      data: data,
    });
    /*     const r = await firstValueFrom(ref.onClose);
    return r; */
  }

  onGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions;
    const [colDefs, sortModel] = this.getColDefsAndSortModel();
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    AgFns.selectFirstRow(gridOptions);
  }

  getColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      { headerName: 'Error', field: 'description', maxWidth: 500 },
      { headerName: 'More Info', field: 'info'}
    ];
    const sortModel: ISortModel = [{ colId: 'index', sort: 'asc' }];
    return [colDefs, sortModel] as const;
  }

  onOk() {
    this.ref.close();
  }
}
