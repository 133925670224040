
<div *ngIf="isPageReady && budgetWrappers" class="flex flex-column w-full h-full">
  <div class="flex my-2">
    <p-button [disabled]="isReadOnly" (click)="onBudgetAdd()" size="small" class="p-button-sm" [label]="addLabel" icon="pi pi-plus"></p-button>
    <span style="margin-left:10px; padding-top: 4px;  font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>
  </div>
  <div class="flex-grow-1 pt-2 w-full h-full">
    <prox-ag-grid [gridOptions]="budgetGridOptions" [rowData]="budgetWrappers">
    </prox-ag-grid>
  </div>
</div>
