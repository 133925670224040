// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { AccountAdminGroupMap } from './account-admin-group-map';
import { JobOrder } from './job-order';
import { ProximityUser } from './proximity-user';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class AccountAdmin extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  get name(): string {
    return this.proximityUser?.name || this.id;
  }
  getStatusParents() {
    return [this.account];
  }

  getStatusId() {
    return this.proximityUser?.activeStatusId;
  }

  getRoleId() {
    return this.proximityUser?.proximityRoleId;
  }

  getRoleName() {
    return this.proximityUser?.proximityRole?.name;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId!: string;
  /** Guid FK proximityUser */
  proximityUserId!: string;
  /** Binary */
  rowVersion!: any;
  /** FK accountId */
  account!: Account;
  /** Inv FK accountAdminId */
  accountAdminGroupMaps!: AccountAdminGroupMap[];
  /** Inv FK approvedByAccountAdminId */
  approvedJobOrders!: JobOrder[];
  /** FK proximityUserId */
  proximityUser!: ProximityUser;
}

