/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BaseService,
  SaveAndQueryComponent,
  StatusService
} from '@core';
import {
  Account,
  AccountAddress,
} from '@models';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountDbSaveService } from '../services/account-db-save.service';

import { UtilFns } from '@utils';
import { EntityError } from 'breeze-client';


@Component({
  selector: 'prox-account-address-frm',
  templateUrl: './account-address-frm.component.html',
})
export class AccountAddressFrmComponent extends SaveAndQueryComponent {
  accountId!: string;
  account!: Account;
  accountAddressId!: string;
  accountAddress!: AccountAddress;
  addressType!: 'B' | 'S' | '' | 'BP' | 'SP';
  addressTypeName!: string;
  isBeingAdded!: boolean;

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    private statusService: StatusService,
    override dbQueryService: AccountDbQueryService,
    override dbSaveService: AccountDbSaveService,

  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }
  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, 'accountId');
    this.accountAddressId = params['accountAddressId'];
    UtilFns.assertNonEmptyString(this.accountAddressId, 'accountAddressId');
    this.addressType = params['addressType']
    UtilFns.assert(this.addressType?.startsWith('B') || this.addressType?.startsWith('S'));
    this.account = await this.dbQueryService.getAccountById(this.accountId);
    UtilFns.assertNonNull(this.account, 'Account');

    this.addressTypeName = this.addressType == 'B' ? 'Billing Address' : 'Shipping Address';
    this.isBeingAdded = this.accountAddressId === 'add';
    if (this.isBeingAdded) {
      this.createNewAddress();
    } else {
      this.accountAddress = await this.dbQueryService.getAccountAddress(this.accountAddressId);
    }
    this.setTitle(`${this.addressTypeName} for ${this.account.name}`);
    this.isPageReady = true;
  }

  override get entity() { return this.accountAddress };

  private createNewAddress() {
    this.accountAddress = this.dbSaveService.createEntity(AccountAddress, {
      accountId: this.accountId,
      isBillingAddress: this.addressType.startsWith('B'),
      isShippingAddress: this.addressType.startsWith('S'),
      isPersonalAddress: this.addressType.endsWith('P'),
      state: null as any,
    });
    this.accountAddress.entityAspect.setUnchanged();
    // console.log("accountAddresses: " + stringify(this.accountAddress));
  }

  // isDisabled(type: 'B' | 'S' | 'P') {
  //   return type == this.addressType  ;
  // }

  isReadOnly() {
    return this.statusService.getWorkingStatus(this.account as any).isActiveReadOnly;
  }

  statusMessage() {
    return this.statusService.getWorkingStatus(this.account as any).statusDisplay;
  }

  override async addCrossValidationErrors() {
    // Dup descriptions may be ok...
    // const ok = await this.dbQueryService.checkIfIsUnique(this.accountAddress, 'id', 'accountId', 'description');
    // if (!ok) {
    //   this.createValidationError(this.accountAddress, 'description', 'This description has already been taken.');
    // }
  }

  override async beforeSave() {
    // because we are faking it out 
    if (this.isBeingAdded) {
      this.accountAddress.entityAspect.setAdded();
    }
    return true;
  }
  
  override async afterSave() {
    this.isBeingAdded = false;
  }

  override async afterUndo(): Promise<void> {
    if (this.isBeingAdded) {
      this.createNewAddress();
      this.accountAddress.entityAspect.clearValidationErrors();
    }
  }
  
  override navigateToValidationError(error: EntityError) {
    const errEnt = error.entity;
    const prop = error.propertyName;
    UtilFns.focusInputByEntity('#topLevel', errEnt, prop);  
  }
}
