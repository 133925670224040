import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImportUser } from '@models';
import { AccountUnitOfWork, DbSaveService } from '@data';
import { MessageBusService, MessageTypeEnum, Roles } from '@core';
import { AccountDbQueryService } from './account-db-query.service';

export interface SupplierPostResult {
  SupplierId: string;
  StatusCode: number;
  ErrorMessage?: string;
  ConfirmationKey?: string
}

export interface NotificationRequest {
  // either NotificationEventId or NotificationTemplateId must be filled in.
  NotificationEventId?: number;
  NotificationTemplateId?: string;
  QueryMap: object; // just a standard object with keys and values as strings.
  AccountId: string;
  ProximityUserId: string;
  Priority: number;
  ExpirationDays?: number;
}


@Injectable({ providedIn: 'root' })
export class AccountDbSaveService extends DbSaveService {
  constructor(
    public override uow: AccountUnitOfWork,
    public dbQueryService: AccountDbQueryService,
    public messageBusService: MessageBusService,
    http: HttpClient,
  ) {
    super(uow, http);
  }

  async postJobOrder(jobOrderId: string) {
    const r = await this.postData<SupplierPostResult[]>('PostJobOrder', { guid: jobOrderId }, null);
    return r;
  }

  async postReturnRequest(returnRequestId: string) {
    const r = await this.postData<SupplierPostResult[]>('PostReturnRequest', { guid: returnRequestId }, null);
    return r;
  }

  async postCancellationRequest(cancellationRequestId: string) {
    const r = await this.postData<SupplierPostResult[]>('PostCancellationRequest', { guid: cancellationRequestId }, null);
    return r;
  }


  async postPendingSupplierSubmissions() {
    const r = await this.postData('PostPendingSupplierSubmissions', null, null);
    return r;
  }

  async postPendingNotificationSubmissions() {
    const r = await this.postData('PostPendingNotificationSubmissions', null, null);
    return r;
  }

  async postResetProximityUserLogin(proximityUserId: string, role: Roles) {
    return this.postData<string>('postResetProximityUserLogin', null, { proximityUserId, role });
  }

  async postImportAccountUsers(accountId: string, importUsers: ImportUser[]) {
    return await this.postData<string>('PostImportAccountUsers', importUsers, { accountId })
  }

  async createNotifications(notificationRequest: NotificationRequest) {
    await this.postData<string>('CreateNotifications', notificationRequest, null);
    this.messageBusService.notify( { id: MessageTypeEnum.Notification } );
  }

  async updateUserAllowanceLogsForUser(accountUserId: string) {
    const r = await this.postData<string>('UpdateUserAllowanceLogsForUser', { guid: accountUserId }, null);
    return r;
  }
}
