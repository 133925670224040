<div *ngIf="isPageReady" class="flex h-full gap-4">
  <div class="flex flex-column h-full w-full">

    <!-- <div class="flex gap-3">
        <label class="mt-2">Template Type: {{templateType.name}}</label>
    </div> -->
    <div class="flex gap-3">
      <div *ngIf="!this.isFreeForm" class="w-6">
        <prox-field label="Notification Event">
          <p-dropdown [disabled]="(!isEditable) || !isNew" [options]="notificationEvents" [(ngModel)]="notificationTemplate.notificationEventId"
            optionLabel="description" optionValue="id" name="event" (onChange)="onNotificationEventChange()"></p-dropdown>
        </prox-field>
      </div>

      <div *ngIf="this.isFreeForm" class="w-6">
        <prox-field label="Template Name">
          <input [disabled]="(!isEditable)" type="text" pInputText autocomplete="off" pAutoFocus [(ngModel)]="notificationTemplate.templateName"
              name="templateName" (change)="onTemplateNameChange()" />
        </prox-field>
      </div>
  
      <div *ngIf="notificationTypes" class="w-4">
        <prox-field label="Notification Type">
          <p-dropdown [disabled]="!isNew" [options]="notificationTypes" [(ngModel)]="notificationTemplate.notificationTypeId"
            optionLabel="description" optionValue="id" name="type"  (onChange)="onNotificationTypeChange()" ></p-dropdown>
        </prox-field>
      </div>

      <div class="w-2">
        
      </div>
    
    </div>

    <div class="flex flex-grow-1">
      <div class="mt-3 mb-3 flex-grow-1">
        <div class="pl-1"><h6>{{messageType()}} Message</h6></div>
        <prox-html-editor [suggestItems]="contextMenuItems" [(value)]="notificationTemplate.template" [isReadOnly]="!isEditable" [isTextOnly]="isSms()"></prox-html-editor>
      </div>
    </div>

    <div class="flex pt-3 pb-2">
      <button *ngIf="isEditable" [disabled]="!canOk()" (click)="onOk()" pButton type="button" label="Ok" icon="pi pi-check" class="p-button-raised mr-2"></button>

      <button (click)="onCancel()" pButton type="button" label="Cancel" icon="pi pi-times"
        class="p-button-raised mr-2"></button>

      <div class="flex-grow-1"></div>

      <button (click)="onPreview()" [disabled]="!canPreview()" pButton type="button" label="Preview" icon="pi pi-eye"
        class="p-button-raised ml-2"></button>

      <button (click)="onSendNotification()" [disabled]="!canSendTest()" pButton type="button" label="Send Test Notification" icon="pi pi-envelope"
        class="p-button-raised ml-2"></button>
        

    </div>
  </div>
</div>