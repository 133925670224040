
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AgFns, UtilDialogService } from '@core';
import { JobOrderBudgetLog, Program, ProgramBudget } from '@models';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgramFrmComponent } from './program-frm.component';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { ToastrService } from 'ngx-toastr';
import { BudgetItemsListComponent, IBudgetWrapper } from '../budgets/budget-items-list.component';
import { Entity } from 'breeze-client';


@Component({
  selector: 'prox-program-budgets-sub',
  templateUrl: './program-budgets.sub.component.html',
})
export class ProgramBudgetsSubComponent implements OnInit, OnChanges {
  @Input() parent!: ProgramFrmComponent;
  @Input() visible!: boolean;
      
  @ViewChild(BudgetItemsListComponent) budgetListItemsComponent!: BudgetItemsListComponent;

  programBudgets: ProgramBudget[] = [];
  joBudgetLogs: JobOrderBudgetLog[] = [];
  
  constructor(
    public dbQueryService: AccountDbQueryService,
    public dbSaveService: AccountDbSaveService,
    public dialogService: UtilDialogService,
    public pngDialogService: DialogService,
    public toastr: ToastrService
  ) {
    //
  }

  get program() {
    return this.parent.program;
  }

  async ngOnInit() {
    const fiscalStartDate = this.program.account.getCurrentYearFiscalStartDate();
    this.joBudgetLogs = await this.dbQueryService.getJobOrderBudgetLogsForProgram(this.program.id, fiscalStartDate);
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  async refresh(forceRefresh = false) {
    if (!this.visible && !forceRefresh) return;
    this.parent.updateComponentNameMap(this);
    
    const isLoaded = this.program.entityAspect.isNavigationPropertyLoaded("programBudgets");
    if (isLoaded && !forceRefresh) {
      this.programBudgets = this.program.programBudgets;
    } else {
      this.programBudgets = await this.dbQueryService.getProgramBudgets(this.program.id);
      this.program.entityAspect.markNavigationPropertyAsLoaded('programBudgets');
    }

    // needed to refresh colDefs based on any changes
    this.budgetListItemsComponent.refreshGrid();
  }

  get programBudgetGridOptions() {
    return this.budgetListItemsComponent?.budgetGridOptions;
  }

  createProgramBudget() {
    const budget = this.dbSaveService.createEntity(ProgramBudget, {
      accountId: this.program.accountId,
      programId: this.program.id,
      budgetProductTypeTagId: null as any,
    });
    return budget;
  }

  async markError(errEnt: Entity, propName?: string) {
    const ent = errEnt as ProgramBudget;
    AgFns.selectGridRowByKey(
      this.programBudgetGridOptions,
      (e: IBudgetWrapper) => e.budget.id,
      ent.id,
      propName
    );
  }
}
