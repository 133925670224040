// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ActiveStatus } from './active-status';
import { ProductFeatureChoice } from './product-feature-choice';
import { ProductType } from './product-type';
import { ProductTypeConfigProduct } from './product-type-config-product';
import { StockCondition } from './stock-condition';
import { ActiveStatusEnum } from './enums';
import { StockConditionEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags
import * as _ from 'lodash';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class Product extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  getStatusParents() {
    return [this.productType];
  }

  getStatusId() {
    return this.activeStatusId;
  }

  // returns the sort order of this product within others of the same type
  // requires that productType.productTypeFeatures.featureChoice have all been fetched.
  getSortOrder() {
    const pt = this.productType;
    if (pt == null) return 0;
    // find ptf with lowest display order
    const ptf = _.first(_.sortBy(pt.productTypeFeatures, ptf => ptf.displayOrder));
    // find featureChoice for this feature
    const pfc = this.productFeatureChoices.find(pfc => pfc.featureChoice.featureId == ptf?.featureId)
    // this is the feature ww will sort on.
    // TODO: later we can add multi-feature sort - instead of just first feature sort.
    return pfc?.featureChoice.displayOrder;
  }

  // requires that productType.productTypeFeatures have been queried
  getOrderedProductFeatureChoices() {
    const featureIdOrder = _.orderBy(this.productType.productTypeFeatures, x => x.displayOrder).map(x => x.featureId)
    const r = _.orderBy(this.productFeatureChoices, x => featureIdOrder.indexOf(x.featureChoice.featureId));
    return r;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** String(200) */
  apiLink?: string;
  /** String(200) */
  apiLinkHint?: string;
  /** String */
  description?: string;
  /** Int32 */
  leadTimeDays!: number;
  /** String(100) */
  productKey!: string;
  /** Guid FK productType */
  productTypeId!: string;
  /** Binary */
  rowVersion!: any;
  /** Enum FK stockCondition */
  stockConditionId!: StockConditionEnum;
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** Inv FK productId */
  productFeatureChoices!: ProductFeatureChoice[];
  /** FK productTypeId */
  productType!: ProductType;
  /** Inv FK productId */
  productTypeConfigProducts!: ProductTypeConfigProduct[];
  /** FK stockConditionId */
  stockCondition!: StockCondition;
}

