/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef } from '@ag-grid-community/core';
import {
  Component
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseListEditFrmComponent, BaseService, ButtonRendererComponent, DateCellEditorPngComponent, IStatusResult, ProxAgFns, Roles, StatusChangeDialog, StatusService } from '@core';
import { Account, AccountProcurementCard, ActiveStatus, ActiveStatusEnum, Program, ProximityRightEnum } from '@models';

import { DateFns, UtilFns } from '@utils';
import * as _ from 'lodash';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { PaymentProfile, PaymentProfileDialogComponent, PaymentProfileDialogData, PaymentService } from '../payments';

@Component({
  selector: 'prox-account-procurement-cards',
  template: BaseListEditFrmComponent.HTML,
})
export class AccountProcurementCardsComponent extends BaseListEditFrmComponent<AccountProcurementCard>  {
  accountId!: string;
  account!: Account;
  paymentProfiles?: PaymentProfile[];
  customerProfileId?: string;
  customerProfileName?: string;
  activeStatuses: ActiveStatus[] = [];

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    private statusService: StatusService,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService,
    private paymentService: PaymentService,
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, "accountId");
    const [r0, r1, r2] = await Promise.all([
      this.dbQueryService.getAccountById(this.accountId),
      this.dbQueryService.getAccountProcurementCards(this.accountId, false),
      this.dbQueryService.getAll(ActiveStatus)
    ]);
    this.account = r0;
    UtilFns.assertNonNull(this.account, 'Account');
    const pcards = r1;
    this.activeStatuses = r2;

    this.initialize(AccountProcurementCard, 'Procurement Card', pcards);
    this.setTitle('Procurement Cards for ' + this.account.name);
    this.statusResult = this.statusService.getWorkingStatus(this.account as any);
    this.isPageReady = true;
  }

  isReadOnly() {
    return this.statusResult?.isActiveReadOnly;
  }

  canEdit() {
    return !this.isReadOnly() && !!this.authUser?.accountId && this.authUser?.hasRight(ProximityRightEnum.CanEditProgram);
  }

  override statusMessage() {
    let msg = '';
    if (this.account?.activeStatusId == ActiveStatusEnum.Active) {
      return 'Account is Active and Not Editable.  To edit, set Account status to Hold.';
    } else if (this.account?.activeStatusId == ActiveStatusEnum.Hold) {
      msg = 'Account is On-Hold and is Editable';
    } else {
      return 'Account is ' + this.account?.activeStatus.name + ' and is Not Editable';
    }
    return msg;
  }

  override isAddDisabled(): boolean {
    return !this.canEdit();
  }

  override async createEntity() {
    // open the Authorize.net form for creating a payment method
    const saved = await this.baseService.dialogService.create<PaymentProfileDialogData>(
      PaymentProfileDialogComponent,
      { title: '', isAccount: true, action: 'addPayment' },
      PaymentProfileDialogComponent.config
    );
    if (!saved) {
      return undefined;
    }

    // reload payment profiles so we can find the new one
    await this.loadCustomerProfile();
    const newProfile = this.findNewProfile();
    if (!newProfile) {
      return undefined;
    }
    
    return this.dbSaveService.createEntity(AccountProcurementCard, { 
      accountId: this.accountId,
      paymentProfileId: newProfile.customerPaymentProfileId,
      name: this.getProfileName(newProfile),
      startDate: DateFns.startOfDay(new Date()),
      endDate: DateFns.startOfDay(DateFns.dateAdd(new Date(), 1, 'year')),
      activeStatusId: ActiveStatusEnum.Active
    });
  }

  private async editPayment(entity: AccountProcurementCard) {
    // open the Authorize.net form for editing a payment method
    const saved = await this.baseService.dialogService.create<PaymentProfileDialogData>(
      PaymentProfileDialogComponent,
      { title: '', isAccount: true, action: 'editPayment', paymentProfileId: entity.paymentProfileId },
      PaymentProfileDialogComponent.config
    );
    if (!saved) {
      return false;
    }
    await this.loadCustomerProfile();
    const profile = this.paymentProfiles?.find(x => x.customerPaymentProfileId == entity.paymentProfileId);
    if (profile) {
      // set name in case it has changed
      entity.name = this.getProfileName(profile);
    }
    return true;
  }

  private getProfileName(profile: PaymentProfile) {
    if (!profile) { return ''; }
    const item = profile.payment.Item as any;
    const name = item.cardType ? item.cardType + ' ' + item.cardNumber : item.bankName + ' ' + item.accountNumber;
    return name;
  }

  /** Load the account's payment profiles from Authorize.net */
  private async loadCustomerProfile() {
    try {
      const result = await this.paymentService.getCustomerProfile(true);
      this.customerProfileId = result.profile.customerProfileId;
      this.customerProfileName = result.profile.description;
      this.paymentProfiles = result.profile.paymentProfiles as PaymentProfile[];
    } catch (e: any) {
      const msg = this.paymentService.getErrorMessage(e);
      this.baseService.toastr.error(msg);
    }
  }

  /** Find the first payment profile id that does not have an associated entity */
  private findNewProfile() {
    const accountProfileIds = new Set(this.entities.map(x => x.paymentProfileId));
    const newProfile = this.paymentProfiles?.find(x => !accountProfileIds.has(x.customerPaymentProfileId));
    return newProfile;
  }


  override async createDefaultEntity(entity: AccountProcurementCard): Promise<AccountProcurementCard | undefined> {
    return undefined;
  }

  override getSortValue(e: AccountProcurementCard) {
    return e.paymentProfileId;
  }

  override getAltKeyValue(e: AccountProcurementCard){
    return e.paymentProfileId;
  }

  override async checkIfInUse(po: AccountProcurementCard)  {
    const isInUse = await this.dbQueryService.checkIfInUse(po, Program, 'accountProcurementCardId')
    return isInUse
  }

  override getColDefs() {
    const columnDefs: ColDef[] = [
      {  headerName: 'Procurement Card', field: 'paymentProfileId',  editable: false, 
        cellRenderer: this.canEdit() ? ButtonRendererComponent : (p) => p.data.name + ' - ' + p.data.id.substring(0,6),
        cellRendererParams: {
          onClick: this.editPayment.bind(this),
          calcLabel: (p) => p.name + ' - ' + p.id.substring(0,6),
        }
      },
      { headerName: 'Description', field: 'description', editable: !this.isReadOnly(), filter: 'agTextColumnFilter' },
      { headerName: 'Start Date', field: 'startDate', editable: !this.isReadOnly(),  cellEditor: DateCellEditorPngComponent, filter: 'agDateColumnFilter' },
      { headerName: 'End Date', field: 'endDate', editable: !this.isReadOnly(),  cellEditor: DateCellEditorPngComponent, filter: 'agDateColumnFilter' },
      { headerName: 'Amount', field: 'amt', editable: !this.isReadOnly(),  filter: 'agNumberColumnFilter' },
      { headerName: 'Amount Used',  filter: 'agNumberColumnFilter', 
        valueGetter: (params) => {
          const x = params.data as AccountProcurementCard;
          if (x == null) return;
          return _.sumBy(x.accountProcurementCardLogs, y => y.amt);
        }, colId: 'usedAmt'  // HACK: colId endsWith amt triggers $ formatting.
      },
      { headerName: 'Status', field: 'activeStatus.name', width: 100, maxWidth: 120, sortable: false, },
      ProxAgFns.getEntityStatusColDef(this.onChangeStatus.bind(this)),
    ];
    return columnDefs;
  }

  private async onChangeStatus(row: AccountProcurementCard) {
    const errs: string[] = [];

    const selectedStatusId = await StatusChangeDialog.open(
      this.dialogService.pngDialogService,
      {
        statuses: this.activeStatuses,
        isAvailable: () => true,
        currentStatus: row.activeStatus,
      },
      { header: 'Procurement Card Status' }
    );

    if (selectedStatusId == row.activeStatusId || selectedStatusId == null) { return; }

    if (!this.selectedRow?.entityAspect.validateEntity()) {
      errs.push('Procurement Card failed validation test.  Fix Procurement Card before changing status.');
      this.dialogService.statusErrorDialog(errs);
      return;
    }

    const proceed = await this.dialogService.verifyProceedDialog(this,
      this.statusService.getStatusExplanation(selectedStatusId, 'Procurement Card'),
      'P-Card Status Change'
    );

    if (proceed) {
      row.activeStatusId = selectedStatusId;
      await this.dbSaveService.saveChanges();
      this.toastr.success('Status change updated', 'Database Activity');
      this.gridApi?.refreshCells();
    }
  }

}
