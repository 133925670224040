import { Component, Input, OnInit } from '@angular/core';
import {
  Gender,
  GenderEnum,
  ProximityUser,
  ActiveStatus,
  ProximityRole
} from '@models';

@Component({
  selector: 'prox-user-frm',
  templateUrl: './user-frm.component.html',
})
export class UserFrmComponent implements OnInit {
  @Input() proximityUser!: ProximityUser;
  @Input() genders?: Gender[];
  @Input() genderModel?: { genderId: GenderEnum }
  @Input() roles?: ProximityRole[];
  @Input() isBeingAdded!: boolean;
  @Input() isReadOnly!: boolean;
  
  isPageReady = false;
  activeStatuses: ActiveStatus[] = [];

  ngOnInit(): void {
/*     if (this.roles && this.roles.length == 0) {
      this.roles = undefined;
    } */
    this.isPageReady = true;
  }
}
