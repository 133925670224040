// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Addon } from './addon';
import { JobOrderHistDetail } from './job-order-hist-detail';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class JobOrderHistDetailAddon extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** String */
  additionalInfo?: string;
  /** Guid FK addon */
  addonId!: string;
  /** Guid FK jobOrderHistDetail */
  jobOrderHistDetailId!: string;
  /** Decimal */
  price!: number;
  /** Boolean */
  wasIncludedInAllowance!: boolean;
  /** Boolean */
  wasOptional!: boolean;
  /** FK addonId */
  addon!: Addon;
  /** FK jobOrderHistDetailId */
  jobOrderHistDetail!: JobOrderHistDetail;
}

