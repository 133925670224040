import { ColDef, GridOptions } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseListEditFrmComponent, BaseService, ProxAgFns, Roles, StatusService } from '@core';
import {
  Account,
  ActiveStatusEnum,
  ProximityAreaEnum,
  ProximityRight,
  ProximityRightEnum,
  ProximityRole,
  ProximityRoleRightMap,
  ProximityUser,
} from '@models';
import { UtilFns } from '@utils';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { EntityFns } from '@data';
import { AccountActivationService } from '../services/account-activation.service';

@Component({
  selector: 'prox-role-list',
  templateUrl: 'account-role-list.component.html',
})
export class AccountRoleListComponent extends BaseListEditFrmComponent<ProximityRole> {
  accountId!: string;
  account!: Account;
  rights!: ProximityRight[];
  area = ProximityAreaEnum.Accounts;

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    private activationService: AccountActivationService,
    private statusService: StatusService,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override statusMessage() {
    if (!this.hasRight(ProximityRightEnum.CanManageAccountAdministration)) {
      return 'Not authorized';
    }
    let msg = '';
    if (this.account?.activeStatusId == ActiveStatusEnum.Active) {
      return 'Account is Active and Not Editable.  To edit, set Account status to Hold.';
    } else if (this.account?.activeStatusId == ActiveStatusEnum.Hold) {
      msg = 'Account is On-Hold and is Editable';
    } else {
      return 'Account is ' + this.account?.activeStatus.name + ' and is Not Editable';
    }
    return msg;
  }

  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, 'accountId');
    const [rights, roles, account] = await Promise.all([
      this.dbQueryService.uow.getAllOrQuery(ProximityRight),
      this.dbQueryService.getAccountRolesByAccountId(this.accountId),
      this.dbQueryService.getAccountById(this.accountId),
    ]);
    this.rights = rights;
    this.account = account;

    await this.initialize(ProximityRole, 'Proximity Role', roles);
    this.setTitle('Proximity Roles');
    this.isPageReady = true;
  }

  override async createEntity() {
    return this.dbSaveService.createEntity(ProximityRole, {
      accountId: this.accountId,
      name: this.account.name + ' New Role',
      description: this.account.name + ' New Role',
      areaId: ProximityAreaEnum.Accounts,
    });
  }

  override isAddDisabled(): boolean {
    return this.statusService.getWorkingStatus(this.account as any).isActiveReadOnly;
  }

  override async createDefaultEntity(entity: ProximityRole): Promise<ProximityRole | undefined> {
    return undefined;
  }

  override getSortValue(e: ProximityRole) {
    return e.name;
  }

  override getAltKeyValue(e: ProximityRole) {
    return e.name;
  }

  override async onDelete(e: ProximityRole) {
    const maps = e.proximityRoleRightMaps.slice();
    const deleted = await super.onDelete(e);
    if (deleted) {
      for (const map of maps) {
        EntityFns.deleteOrDetach(map.entityAspect);
      }
    }
    return deleted;
  }

  override async checkIfInUse(po: ProximityRole) {
    const isInUse = await this.dbQueryService.checkIfInUse(po, ProximityUser, 'proximityRoleId');
    return isInUse;
  }

  override getColDefs() {
    const columnDefs: ColDef[] = [
      { headerName: 'Name', field: 'name', editable: (p) => this.canEdit(p.data), filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'description', editable: (p) => this.canEdit(p.data), filter: 'agTextColumnFilter' },
      { headerName: 'Type', valueGetter: (p) => this.getTypeLabel(p.data), editable: false, filter: 'agTextColumnFilter' },

      ProxAgFns.getEntityDeleteColDef(this.onDelete.bind(this), { canDisplay: this.canEdit.bind(this) }),
    ];
    return columnDefs;
  }

  canEdit(proximityRole: ProximityRole) {
    return (
      (proximityRole && proximityRole.accountId == this.accountId) ||
      this.authUser?.hasRight(ProximityRightEnum.CanManageAccountAdministration) ||
      false
    );
  }

  private getTypeLabel(proximityRole: ProximityRole) {
    return proximityRole && proximityRole.accountId == this.accountId ? this.account.name : 'Global';
  }
}
