/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, GridOptions, GridReadyEvent, RowSelectedEvent } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgFns, BaseService, ISortModel, ProxAgFns, QueryComponent, Roles, StatusChangeDialog, StatusService } from '@core';
import { Account, AccountAdmin, ActiveStatus, ActiveStatusEnum, JobOrder, JobOrderStatusEnum, ProximityRightEnum } from '@models';
import { AccountDbQueryService } from './../services/account-db-query.service';
import { UtilFns } from '@utils';
import * as _ from 'lodash';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'prox-account-administrator-list',
  templateUrl: './account-administrator-list.component.html',
})
export class AccountAdminListComponent extends QueryComponent {
  accountId?: string;
  account?: Account;
  administrators: AccountAdmin[] = [];
  activeStatuses: ActiveStatus[] = [];
  editLabel = 'Edit';
  editLabelWidth = 50;
  gridOptions: GridOptions = {};
  joGridOptions: GridOptions = {};
  jobOrders: JobOrder[] = [];
  includeHierarchy = false;

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    override dbQueryService: AccountDbQueryService,
    public dbSaveService: AccountDbSaveService,
    private statusService: StatusService,
    public pngDialogService: DialogService
  ) {
    super(baseService, route, dbQueryService);
  }

  async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, 'accountId');
    this.administrators = await this.dbQueryService.getAccountAdminsByAccountId(this.accountId);
    this.account = await this.dbQueryService.getAccountById(this.accountId);
    UtilFns.assertNonNull(this.account, 'Account');
    this.activeStatuses = await this.dbQueryService.getAll(ActiveStatus);
    this.gridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onGridReady,
      onRowSelected: this.onAccountAdminRowSelected,
    });
    AgFns.captureGridRouteParams(this.gridOptions, this.route, 'id');

    if (this.statusService.getWorkingStatus(this.account as any).isActiveReadOnly) {
      this.editLabel = 'Read Only';
      this.editLabelWidth = 100;
    }

/*     this.joGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJobOrderGridReady,
      rowModelType: 'clientSide',
      // needed because this grid contains a collecton of both real and 'fake' joborders
      getRowId: (rowIdParams: GetRowIdParams) => {
        const possibleJo = rowIdParams.data as JobOrder;
        return possibleJo.id ?? '' + '-' + possibleJo.programId;
      },
    }); */
    
    this.setTitle('Account Administrators for ' + this.account.name);
    this.isPageReady = true;
  } 

  onGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions;
    const [colDefs, sortModel] = this.getColDefsAndSortModel();
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    AgFns.applyGridRouteParams(gridOptions);
  }

  getColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      {
        ...AgFns.createButtonProps('', this.onEdit.bind(this), { label: this.editLabel, canDisplay: this.canEditAdmin.bind(this) }),
        width: this.editLabelWidth,
        maxWidth: this.editLabelWidth,
      },
      { headerName: 'Name', field: 'proximityUser.fullName', filter: 'agTextColumnFilter' },
      /*       { headerName: 'Last Name', field: 'proximityUser.lastName', maxWidth: 150, filter: 'agTextColumnFilter' },
      { headerName: 'First Name', field: 'proximityUser.firstName', maxWidth: 150, filter: 'agTextColumnFilter' },
      { headerName: 'Middle Name', field: 'proximityUser.middleName', maxWidth: 100, filter: 'agTextColumnFilter' },
      { headerName: 'Salutation', field: 'proximityUser.salutation', maxWidth: 100, filter: 'agTextColumnFilter' }, */
      {
        headerName: 'Role',
        field: 'proximityUser.proximityRole.name',
        filter: 'agTextCOlumnFilter',
      },
      { headerName: 'User Name', field: 'proximityUser.username', maxWidth: 150, filter: 'agTextColumnFilter' },
      {
        ...AgFns.createButtonProps('', this.onResetLogin.bind(this), { label: 'Reset Login', canDisplay: this.canEditAdmin.bind(this) }),
        width: 100,
        maxWidth: 100,
      },
      { headerName: 'Title', field: 'proximityUser.title', filter: 'agTextColumnFilter' },

      { headerName: 'E-Mail', field: 'proximityUser.email', filter: 'agTextColumnFilter' },
      ProxAgFns.getWorkingStatusDef('Working Status', this.statusService),
      {
        headerName: 'Admin Status',
        field: 'proximityUser.activeStatus.name',
        width: 100,
        maxWidth: 120,
        sortable: false,
      },
      ProxAgFns.getEntityStatusColDef(this.onChangeStatus.bind(this),  { canDisplay: this.isEditable.bind(this) }),
    ];
    const sortModel: ISortModel = [
      { colId: 'proximityUser.lastName', sort: 'asc' },
      { colId: 'proximityUser.firstName', sort: 'asc' },
    ];
    return [colDefs, sortModel] as const;
  }

  isEditable() {
    return this.account!.activeStatusId === ActiveStatusEnum.Hold;
  }

  async onChangeStatus(row: AccountAdmin) {
    const errs: string[] = [];

    const selectedStatusId = await StatusChangeDialog.open(
      this.pngDialogService,
      {
        statuses: this.activeStatuses,
        isAvailable: () => true,
        currentStatus: row.proximityUser.activeStatus,
      },
      {
        header: 'Account Administrator Status',
      }
    );

    if (selectedStatusId == row.proximityUser.activeStatusId) {
      return;
    }

    if (selectedStatusId != null) {
      if (!row.entityAspect.validateEntity() || !row.proximityUser.entityAspect.validateEntity()) {
        errs.push('Account Administrator failed validation test.  Fix Account Administrator before changing status.');
      }
    }

    if (selectedStatusId != ActiveStatusEnum.Active) {
      if (this.account?.primaryAccountAdminId == row.id) {
        errs.push(
          'Primary Account Administrator must be status: Active.  To change the status of this Account Administrator, select a different Primary Administrator for this Account, first.'
        );
      }
    }

    if (errs.length > 0) {
      this.dialogService.statusErrorDialog(errs);
      return;
    }

    if (selectedStatusId != null) {
      row.proximityUser.activeStatusId = selectedStatusId;
      this.dialogService.verifyProceedDialog(
        this,
        this.statusService.getStatusExplanation(selectedStatusId, 'Account Administrator'),
        'Account Administrator Status Change'
      );
    }
  }

  async proceedDialog() {
    await this.dbSaveService.saveChanges();
    this.toastr.success('Status change updated', 'Database Activity');
    this.gridOptions.api?.refreshCells();
  }

  cancelDialog() {
    this.dbSaveService.rejectChanges();
    this.gridOptions.api?.refreshCells();
  }

/*   onJobOrderGridReady() {
    const colDefs: ColDef[] = [
      {
        ...AgFns.createButtonProps(' ', this.onGotoJo.bind(this), { calcLabel: this.calcJoLabel.bind(this) }),
        minWidth: 160,
        maxWidth: 160,
      },

      { headerName: 'Order', field: 'displayId', maxWidth: 120 },
      { headerName: 'Program Name', field: 'program.name' },
      { headerName: 'Account User', field: 'accountUser.proximityUser.name' },
      { headerName: 'Organization Tree Admin Group', field: 'accountAdminGroup.name' },
      { headerName: 'Approved by', field: 'approvedByAccountAdmin.proximityUser.name' },
      { headerName: 'Status', field: 'jobOrderStatus.name', maxWidth: 140 },
      { headerName: 'Created', field: 'orderCreatedDate', valueFormatter: AgFns.dateTimeFormatter, maxWidth: 130 },
      { headerName: 'Placed', field: 'orderPlacedDate', valueFormatter: AgFns.dateTimeFormatter, maxWidth: 130 },
    ];
    AgFns.initGrid(this.joGridOptions, colDefs);
  } */

  canEditAdmin(accountAdmin: AccountAdmin) {
    // const accountAdmin = row as AccountAdmin;
    return (
      this.authUser?.hasRight(this.ProximityRightEnum.CanManageAccountAdministration) || this.authUser?.accountAdminId == accountAdmin.id
    );
  }

  onEdit(row: AccountAdmin) {
    this.updateGridRouteParams(this.gridOptions, row.id);
    this.router.navigate(['account/accounts', this.accountId, 'manage', 'account-administrators', row.id]);
  }

  async onResetLogin(row: AccountAdmin) {
    await this.dbSaveService.postResetProximityUserLogin(row.proximityUser.id, Roles.PrincipalAccountAdmin);
    this.toastr.success('Account Administrator Login has been reset.');
  }

  isReadOnly() {
    return (
      !this.hasRight(ProximityRightEnum.CanManageAccountAdministration) ||
      this.statusService.getWorkingStatus(this.account as any).isActiveReadOnly
    );
  }

  statusMessage() {
    if (!this.hasRight(ProximityRightEnum.CanManageAccountAdministration)) {
      return 'Not authorized';
    }

    let msg = '';
    if (this.account?.activeStatusId == ActiveStatusEnum.Active) {
      return 'Account is Active and Not Editable.  To edit, set Account status to Hold.';
    } else if (this.account?.activeStatusId == ActiveStatusEnum.Hold) {
      msg = 'Account is On-Hold and is Editable';
    } else {
      return 'Account is ' + this.account?.activeStatus.name + ' and is Not Editable';
    }
    return msg;
  }

  async onAdd() {
    if (this.isReadOnly()) {
      await this.dialogService.okDialog('Action Not Available', 'Account Administrators may not be added to inactive Account');
      return;
    }
    this.router.navigate(['account/accounts', this.accountId, 'manage', 'account-administrators', 'add']);
  }

  async onAccountAdminRowSelected(event: RowSelectedEvent) {
    if (!event.node.isSelected()) return;
    this.onRowSelectedDefault(event);
    const aa = event.data as AccountAdmin;
    this.onAccountAdminChanged(aa);
  }

  async onAccountAdminChanged(aa: AccountAdmin) {
    if (aa == null) return;
    this.jobOrders = await this.dbQueryService.getJobOrdersByAccountAdminId(aa.id, this.includeHierarchy);
    this.jobOrders = _.sortBy(this.jobOrders, (jo) => {
      const status = jo.jobOrderStatusId == JobOrderStatusEnum.AwaitingApproval ? -100 : jo.jobOrderStatusId;
      return [status, jo.orderCreatedTs];
    });
    AgFns.refreshGrid(this.joGridOptions, this.jobOrders);
  }

/*   onGotoJo(jobOrder: JobOrder) {
    if (jobOrder.jobOrderStatusId == JobOrderStatusEnum.Placed || jobOrder.jobOrderStatusId == JobOrderStatusEnum.Closed) {
      this.router.navigate(['account-user/accounts', jobOrder.program.accountId, 'job-orders-hist', jobOrder.id]);
    } else {
      this.router.navigate([
        'account-user/accounts',
        jobOrder.program.accountId,
        'job-orders',
        jobOrder.id,
        jobOrder.programId,
        jobOrder.accountUserId,
      ]);
    }
  }

  calcJoLabel(jobOrder: JobOrder) {
    if (jobOrder.jobOrderStatusId == JobOrderStatusEnum.Placed) {
      return 'View Order';
    } else {
      return 'Edit/Approve Order';
    }
  } */
}
