/* eslint-disable @angular-eslint/component-selector */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, IMessage, ImpersonationService, MessageBusService, MessageTypeEnum } from '@core';
import { AppSidebarComponent } from './app.sidebar.component';
import { LayoutService } from './service/app.layout.service';
import { HasChangesService } from '@data';
import { AccountDbQueryService } from '@account';
import { ProximityUser } from '@models';
import { sha256 } from 'js-sha256';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent implements OnInit {
    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

    newNotificationsCount = 0;
    authUser?: ProximityUser;
    intervalHandle!: number;
    f = 'mp';
    emHash = '';

    constructor(public layoutService: LayoutService, private authService: AuthService, private impersonationService: ImpersonationService,
        private dbQueryService: AccountDbQueryService, private messageBusService: MessageBusService,
        private hasChangesService: HasChangesService, private router: Router, public el: ElementRef) { }

    async ngOnInit(): Promise<void> {
        const authUser = await this.authService.getLoggedInUser();
        this.authUser = authUser?.actingAsUser ?? authUser;

        if (this.authUser?.email) {
            const c = sha256(this.authUser?.email);
            this.emHash = c;
        }
        
        this.messageBusService.messages.subscribe(this.checkMessageBus.bind(this));

        // check notifications immediately, then check every 5 minutes. 
        this.intervalHandle = window.setInterval(this.checkInAppNotifications, 1000*60*5); 
    }

    ngOnDestroy() {
        clearInterval(this.intervalHandle);
    }

    private async checkMessageBus(message: IMessage) {
        if (message.id == MessageTypeEnum.Noop) {
            this.checkInAppNotifications();
        } else  if (message.id == MessageTypeEnum.Login) {
            // do something maybe 
        } else if (message.id == MessageTypeEnum.Notification) {
            await this.checkInAppNotifications();
        }
        return null;
    }

    private async checkInAppNotifications() {
        if (this.authUser == null) { return; }
        const newNotifications = await this.dbQueryService.getInAppNotifications(this.authUser.id, true);
        this.newNotificationsCount = newNotifications.length;
    }

    get userDisplayName(): string {
        return this.authService.userDisplayName;
    }

    get companyName() {
        //return this.authService.getUser()?.companyName;
        return '';
    }

    get gravatarLink() {
        return "https://www.gravatar.com/avatar/" + this.emHash + "?d=mp";
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onProfileButtonClick() {
        this.layoutService.showRightMenu();
    }

    onSearchClick() {
        this.layoutService.toggleSearchBar();
    }

    onRightMenuClick() {
        this.layoutService.showRightMenu();
    }

    get logo() {
        const logo = this.layoutService.config.menuTheme === 'white' || this.layoutService.config.menuTheme === 'orange' ? 'dark' : 'white';
        return logo;
    }

    get hasChanges() {
        return this.hasChangesService.hasChanges;
    }

    get impersonateTooltip() {
        return this.hasChanges ? 'Save changes first' : null;
    }

    setGravatar() {
        
    }

    changePassword() {
        this.router.navigate(['/change-password']);
    }

    goToLogin() {
        this.authService.redirectIfNotLoggedIn();
    }

    logout() {
        this.authService.logout();
    }

    canImpersonate() {
        return this.impersonationService.canImpersonateAccountUser();
    }

    impersonate() {
        if (!this.hasChanges) {
            this.impersonationService.openDialog();
        }
    }

    canStopImpersonation() {
        return !!this.authService.getUser()?.actingAsUser;
    }
    stopImpersonation() {
        if (!this.hasChanges) {
            return this.authService.stopImpersonation();
        }
    }
}
