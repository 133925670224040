import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgFns, BaseService, BreadcrumbService, ISortModel, QueryComponent, RouteFns } from '@core';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { Account, ProximityRightEnum } from '@models';
import { AccountDbQueryService } from './services/account-db-query.service';
import { UtilFns } from '@utils';
import { accountRoutes } from './account.routes';
import { AccountActivationService, ICheckListItem } from './services/account-activation.service';
import { ColDef, GetRowIdParams, GridOptions, GridReadyEvent } from '@ag-grid-community/core';

@Component({
  selector: 'prox-account-manager',
  templateUrl: './account-manager.component.html',
})
export class AccountManagerComponent extends QueryComponent {
  items: MenuItem[] = [];
  breadcrumbItems: MenuItem[] = [];

  accountId?: string;
  accountName?: string;
  rightsMap: { [path: string]: ProximityRightEnum[] } = {};
  isValidating = false;

  account?: Account;
  accountForActivation!: Account | undefined;
  activationErrors: ICheckListItem[] = [];
  errsGridOptions: GridOptions = {};

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    private primengConfig: PrimeNGConfig,
    private breadcrumbService: BreadcrumbService,
    override dbQueryService: AccountDbQueryService
  ) {
    super(baseService, route, dbQueryService);
    RouteFns.populateRightsMap('/account', accountRoutes, this.rightsMap);
  }

  async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, 'accountId');

    const result = await this.dbQueryService.fetchEntityByKey(Account, this.accountId, true);
    this.accountName = (result.entity as Account)?.name;

    if (this.authUser?.hasRight(ProximityRightEnum.IsSuperUserForAccounts)) {
      this.breadcrumbService.setItems([
        { label: 'List Accounts', routerLink: ['/account/accounts'] },
        { label: 'Manage Account: ' + this.accountName },
      ]);
    } else {
      this.breadcrumbService.setItems([{ label: this.accountName }]);
    }

    this.primengConfig.ripple = true;

    /* if ((!this.authUser?.hasRight(ProximityRightEnum.CanManageAccountAdministration)) || this.activationService.isActivated) { */
    if ((!this.authUser?.hasRight(ProximityRightEnum.CanManageAccountAdministration))) {
      this.items = [];
      this.isPageReady = true;
      return;
    }

    this.items = [
      {
        label: '<span class="text-l font-bold">Administration</span>',
        escape: false,
        items: [
          {
            label: 'Information',
            icon: 'pi pi-fw pi-cog',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'account-info']
          },
          {
            label: 'Administrators',
            icon: 'pi pi-fw pi-user',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'account-administrators'],
          },
          {
            label: 'Administrator Groups',
            icon: 'pi pi-fw pi-user',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'account-admin-groups'],
          },
          {
            label: 'Roles',
            icon: 'pi pi-fw pi-user',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'account-roles'],
          },
        ],
      },
      {
        label: '<span class="text-l font-bold">Organization</span>',
        escape: false,
        items: [
          {
            label: 'Account Users',
            icon: 'pi pi-fw pi-truck',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'account-users'],
          },
          {
            label: 'Budget Product Tags',
            icon: 'pi pi-fw pi-truck',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'budget-product-type-tags'],
          },
          {
            label: 'Budgets',
            icon: 'pi pi-fw pi-truck',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'account-budgets'],
          },
          {
            label: 'Shipping User Groups',
            icon: 'pi pi-fw pi-truck',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'shipping-user-groups'],
          },
          {
            label: 'Shipping Addresses',
            icon: 'pi pi-fw pi-truck',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'shipping-account-addresses'],
          },
          {
            label: 'Billing Addresses',
            icon: 'pi pi-fw pi-truck',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'billing-account-addresses'],
          },

          {
            label: 'Product Catalog',
            icon: 'pi pi-fw pi-user',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'account-price-lists'],
          },
          {
            label: 'Notification Templates',
            icon: 'pi pi-fw pi-user',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'account-notification-templates'],
          },
          {
            label: 'Notification Settings',
            icon: 'pi pi-fw pi-user',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'account-notification-settings'],
          },
        ],
      },
      {
        label: '<span class="text-l font-bold">Program Preparation</span>',
        escape: false,
        items: [
          {
            label: 'Product Tags',
            icon: 'pi pi-fw pi-truck',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'program-product-type-tags'],
          },
          {
            label: 'Program User Groups',
            icon: 'pi pi-fw pi-truck',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'program-user-groups'],
          },
          {
            label: 'Purchase Orders',
            icon: 'pi pi-fw pi-truck',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'account-blanket-purchase-orders'],
          },
          {
            label: 'Procurement Cards',
            icon: 'pi pi-fw pi-credit-card',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'account-procurement-cards'],
          },
          {
            label: 'Proximity Organization',
            icon: 'pi pi-fw pi-truck',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'approval-trees'],
          },
        ],
      },
      {
        label: '<span class="text-l font-bold">Programs</span>',
        escape: false,
        items: [
          {
            label: 'Programs',
            icon: 'pi pi-fw pi-truck',
            routerLink: ['/account/accounts', ':accountId', 'manage', 'programs'],
          },
        ],
      },
    ];

    const paramMap = { ':accountId': this.accountId };
    RouteFns.processMenuItems(this.items, paramMap, this.rightsMap, this.authUser);

    this.isPageReady = true;
  }
}
